import ID from './ID'
import PlanDashboardCategory from './PlanDashboardCategory'

export default class PlanDashboard {
  constructor(
    public id: ID = new ID(),
    public name: string = '',
    public embedHtml: string = '',
    public isArchived: boolean = false,
    public plan: ID = new ID(),
    public category: PlanDashboardCategory = new PlanDashboardCategory(),
    public sortOrder: number = 0,
  ) {}

  public get key() {
    return 'plan-dashboard-' + this.id.intID.toString()
  }

  public static fromResponseObject(obj): PlanDashboard {
    if (!obj) return new PlanDashboard()
    return new PlanDashboard(
      ID.fromResponseObject(obj.id, 'plan_dashboards'),
      obj.name,
      obj.embedHtml,
      obj.isArchived,
      ID.fromResponseObject(obj.plan, 'plans'),
      PlanDashboardCategory.fromResponseObject(obj.category),
      obj.orderIndex,
    )
  }

  public forRequestObject() {
    return {
      id: this.id.intID == 0 ? null : this.id.apiID,
      name: this.name,
      embedHtml: this.embedHtml,
      isArchived: this.isArchived,
      plan: this.plan.apiID,
      orderIndex: this.sortOrder,
      category: this.category.id.intID == 0 ? null : this.category.id.apiID,
    }
  }
}
