<template>
  <div class="view-wrapper">
    <ViewHeader
      title="Performance"
      :isFullViewport="true"
      :hideSectionHomeButton="true"
      :hideRoleTutorial="true"
    >
      <template #actions>
        <Button
          @click="()=>{
            $emit('complete')
            $router.back()
          }"
          label="Close"
          class="p-button-outlined p-ml-2"
        />
      </template>
    </ViewHeader>
    <ViewMain 
      :isFullViewport="true"
    >
      <div class="plan-settings-modal-content" style="max-width: 100rem; margin: 0 auto;">
        <div class="p-field">
          <div class="p-d-flex top-items">
            <p class="plan-settings-instructions">
              Centralize your performance data so insights are at the ready to inform tactical planning. Embed Looker Studio dashboards, reports built in Google Slides, and other data sources for access within a Performance view.
            </p>
            <p class="plan-settings-instructions">
              Click on the caret icon under Category and select the appropriate category or choose [New Category] to enter a new category name. Enter the name of the data source. Copy the HTML embed code for the data source and paste it in the designated box. Click (+) Performance Data to embed.
            </p>
          </div>
        </div>

        <div class="setup-checkboxes">
          <p class="checkboxes-text-first">Performance Feature:</p>
          <div class="checkbox-container">
            <Checkbox
              id="dashboardOn"
              :binary="true"
              v-model="useDashboardsOn"
              class="p-mr-2 checkbox"
              @change="() => handleToggleUseDashboards(true)"
            />
            <label for="dashboardOn">On</label>
          </div>
          <div class="checkbox-container">
            <Checkbox
              id="dashboardOff"
              :binary="true"
              v-model="useDashboardsOff"
              class="p-mr-2 checkbox"
              @change="() => handleToggleUseDashboards(false)"
            />
            <label for="dashboardOff">Off</label>
          </div>
        </div>

        <template v-if="useDashboardsOn">
          <div class="category-section">
            <Container
              @drop="(dropResults) => onCategoryReorder(dropResults, filteredCategoriesActive)"
              drag-handle-selector=".drag-icon--dashboard-category"
            >
              <Draggable
                v-for="category in filteredCategoriesActive"
                :key="category.key"
              >
              <div class="p-d-flex" style="gap: 1rem;">
                <span class="drag-icon drag-icon--dashboard-category">&#x2630;</span>
                <DataTable
                  class="active-dashboards-table" 
                  :value="category.activeDashboards"
                  dataKey="key" 
                  :key="category.key"
                  :autoLayout="true"
                  editMode="row" 
                  :editingRows.sync="activeEditingRows" 
                  @row-edit-init="onDashboardRowEditInit"
                  @row-edit-save="onDashboardRowEditSave"
                  @row-reorder="onDashboardRowReorder"
                >
                  <Header 
                    :id="`${category.key}-header`"
                    class="category-name-header"
                  >
                    <div>
                      <h2
                        v-if="!editingCategoryNames.filter((obj) => obj.id === category.id.intID).length"
                      >
                        {{ category.name }}
                      </h2>
                      <InputText 
                        v-if="editingCategoryNames.filter((obj) => obj.id === category.id.intID).length"
                        v-model="category.name" 
                      />
                    </div>
                     <div>
                      <Button 
                        v-if="!editingCategoryNames.filter((obj) => obj.id === category.id.intID).length"
                        icon="pi pi-pencil" 
                        class="p-button-text"
                        @click="onCategoryNameEditInit(category)" 
                      />
                      <Button 
                        v-if="editingCategoryNames.filter((obj) => obj.id === category.id.intID).length"
                        icon="pi pi-check" 
                        class="p-button-text"
                        @click="onCategoryNameEditSave(category)" 
                      />
                      <Button 
                        v-if="editingCategoryNames.filter((obj) => obj.id === category.id.intID).length"
                        icon="pi pi-times" 
                        class="p-button-text"
                        @click="onCategoryNameEditCancel(category)" 
                      />
                     </div>
                  </Header>
                  <Column 
                    rowReorder 
                    v-if="canUserEditPlanProperties"
                  />
                  <Column 
                    field="name" 
                    bodyClass="name-column"
                  >
                    <template #body="slotProps">
                      <div class="p-d-flex p-jc-between p-ai-center">
                        <div>
                          {{ slotProps.data.name }}
                          <template v-if="$store.getters.currentUser.isSuperUser">
                            <span class="id-value"> ({{ slotProps.data.id.intID }})</span>
                          </template>
                        </div>
                      </div>
                    </template>
                    <template #editor="slotProps">
                      <div class="p-d-flex p-jc-between p-ai-start" style="gap: 4rem;">
                        <InputText v-model="slotProps.data.name" />
                        <Textarea v-model="slotProps.data.embedHtml" />
                      </div>
                    </template>
                  </Column>
                  <Column 
                    :rowEditor="true" 
                    v-if="canUserEditPlanProperties"
                  ></Column>
                  <Column 
                    v-if="canUserEditPlanProperties"
                  >
                    <template #body="slotProps">
                      <Button 
                        icon="pi pi-folder" 
                        severity="warning" 
                        class="p-button-text p-button-warning"
                        @click="onActiveDashboardArchive($event, slotProps.data)" 
                      />
                    </template>
                    <template #editor>
                      <Button 
                        icon="pi pi-folder" 
                        severity="warning" 
                        class="p-button-text p-button-warning"
                        disabled
                      />
                    </template>
                  </Column>
                  <Column 
                    v-if="canUserEditPlanProperties"
                  >
                    <template #body="slotProps">
                      <Button 
                        icon="pi pi-trash" 
                        severity="danger" 
                        class="p-button-text p-button-danger"
                        @click="onDashboardDelete($event, slotProps.data)" 
                      />
                    </template>
                    <template #editor>
                      <Button 
                        icon="pi pi-trash" 
                        severity="danger" 
                        class="p-button-text p-button-danger"
                        disabled
                      />
                    </template>
                  </Column>
                </DataTable>
              </div>
            </Draggable>
          </Container>
            
            <div class="add-dashboard d-flex p-jc-center">
              <div class="field">
                <label for="dashboard-category-add" style="display: block;">Category</label>
                <Dropdown
                  id="dashboard-category-add"
                  v-model="newDashboard.category"
                  optionLabel="name"
                  :options="categoriesOptions"
                  dataKey="key"
                  @change="() => (isSelectedCategory = true)"
                  :disabled="!shouldAllowFormInput"
                />
              </div>
              <div
                class="field"
                v-if="shouldShowNewCategoryNameField"
              >
                <label for="new-category-name">New Category Name</label>
                <InputText
                  id="new-category-name"
                  class="new-category-name"
                  v-model="newCategoryName"
                  :disabled="!shouldAllowFormInput"
                />
              </div>
              <div class="field">
                <label for="new-dashboard-name">Data Source Name</label>
                <InputText
                  id="new-dashboard-name"
                  class="new-dashboard-name"
                  v-model="newDashboard.name"
                  :disabled="!shouldAllowFormInput"
                />
              </div>
              <div class="field">
                <label for="new-dashboard-embed-html">HTML Embed Code</label>
                <Textarea
                  id="new-dashboard-embed-html"
                  class="new-dashboard-embed-html"
                  v-model="newDashboard.embedHtml"
                  :disabled="!shouldAllowFormInput"
                />
              </div>
              <div class="add-button" @click="handleAddDashboard">
                <i class="pi pi-plus-circle p-mr-1"></i>
                <div>Performance Data</div>
              </div>
            </div>
          </div>

          <div class="archive-section">
            <p class="plan-settings-instructions">Click the folder icon to the right of the data sources above to file them under Archive  in the Performance view.</p>
            <div class="archive-header">
              <h2>Archive <i class="pi pi-folder p-ml-4"></i></h2>
            </div>
             <template v-for="category in filteredCategoriesArchive">
              <DataTable
                  class="active-dashboards-table" 
                  :value="category.archivedDashboards" 
                  dataKey="key" 
                  :key="category.key"
                  :autoLayout="true"
                  editMode="row" 
                  :editingRows.sync="archiveEditingRows" 
                  @row-edit-init="onDashboardRowEditInit"
                  @row-edit-save="onDashboardRowEditSave"
                  @row-reorder="onDashboardRowReorder"
                >
                  <Header>
                    <h2>{{ category.name }}</h2>
                  </Header>
                  <Column 
                    rowReorder 
                    v-if="canUserEditPlanProperties"
                  />
                  <Column 
                    field="name" 
                    bodyClass="name-column"
                  >
                    <template #body="slotProps">
                      <div class="p-d-flex p-jc-between p-ai-center">
                        <div>
                          {{ slotProps.data.name }}
                          <template v-if="$store.getters.currentUser.isSuperUser">
                            <span class="id-value"> ({{ slotProps.data.id.intID }})</span>
                          </template>
                        </div>
                      </div>
                    </template>
                    <template #editor="slotProps">
                      <div class="p-d-flex p-jc-between p-ai-start" style="gap: 4rem;">
                        <InputText v-model="slotProps.data.name" />
                        <Textarea v-model="slotProps.data.embedHtml" />
                      </div>
                    </template>
                  </Column>
                  <Column 
                    :rowEditor="true" 
                    v-if="canUserEditPlanProperties"
                  ></Column>
                  <Column 
                    v-if="canUserEditPlanProperties"
                  >
                    <template #body="slotProps">
                      <Button 
                        icon="pi pi-folder-open" 
                        severity="warning" 
                        class="p-button-text p-button-warning"
                        @click="onArchivedDashboardUnarchive($event, slotProps.data)" 
                      />
                    </template>
                    <template #editor>
                      <Button 
                        icon="pi pi-folder-open" 
                        severity="warning" 
                        class="p-button-text p-button-warning"
                        disabled
                      />
                    </template>
                  </Column>
                  <Column 
                    v-if="canUserEditPlanProperties"
                  >
                    <template #body="slotProps">
                      <Button 
                        icon="pi pi-trash" 
                        severity="danger" 
                        class="p-button-text p-button-danger"
                        @click="onDashboardDelete($event, slotProps.data)" 
                      />
                    </template>
                    <template #editor>
                      <Button 
                        icon="pi pi-trash" 
                        severity="danger" 
                        class="p-button-text p-button-danger"
                        disabled
                      />
                    </template>
                  </Column>
                </DataTable>
             </template>
            
          </div>
        </template>

      </div>
    </ViewMain>
  </div>
</template>

<script lang="ts">
import Vue from 'vue'
import ViewHeader from '@/components/ViewHeader.vue'
import ViewMain from '@/components/ViewMain.vue'
import InputSwitch from 'primevue/inputswitch'
import Plan from '@/models/Plan'
import {AlertMessageSeverity} from '@/models/AlertMessage'
import Dialog from 'primevue/dialog'
import Role from '@/models/Role'
import ID from '@/models/ID'
import DataTable from 'primevue/datatable/DataTable'
import PlanDashboard from '@/models/PlanDashboard'
import PlanDashboardCategory from '@/models/PlanDashboardCategory'
import Textarea from 'primevue/textarea/Textarea'
import {Container, Draggable} from 'vue-smooth-dnd'
import {applyDrag} from '@/utils/applyDrag'

Vue.component('Dialog', Dialog)
Vue.component('InputSwitch', InputSwitch)

export default Vue.extend({
  name: 'PlanSettingsDashboards',
  components: {
    ViewHeader,
    ViewMain,
    Container,
    Draggable,
  },
  props: {
    shouldShow: Boolean,
  },
  data: () => {
    return {
      newDashboard: {} as PlanDashboard,
      newCategoryName: '' as string,
      newDashboardCategory: {} as PlanDashboardCategory,
      shouldAllowFormInput: true as boolean,
      isSelectedCategory: false as boolean,
      activeEditingRows: [],
      archiveEditingRows: [],
      editingCategoryNames: [] as {id, originalName}[],
    }
  },
  computed: {
    plan(): Plan {
      return this.$store.getters.currentPlan
    },
    canUserManagePlanUsersChannelsSubscriptions(): boolean {
      return this.$store.getters.getPermissionLevelForPlanId(this.plan.id) <=
        Role.LEVEL_PLAN_ADMIN
        ? true
        : false
    },
    canUserEditPlanProperties(): boolean {
      return this.$store.getters.getPermissionLevelForPlanId(this.plan.id) <=
        Role.LEVEL_PLAN_ADMIN
        ? true
        : false
    },
    useDashboardsOn: {
      get: function (): boolean {
        return this.plan.useDashboards
      },
      set: function (newValue: boolean) {
        return newValue
      },
    },
    useDashboardsOff: {
      get: function (): boolean {
        return !this.plan.useDashboards
      },
      set: function (newValue: boolean) {
        return newValue
      },
    },
    filteredCategoriesActive(): PlanDashboardCategory[] {
      return this.plan.planDashboardCategories.filter((category)=>{
        return category.dashboards.filter((dashboard)=>{
          return !dashboard.isArchived
        }).length > 0
      }).sort((a, b) => {
        return a.sortOrder - b.sortOrder
      })
    },
    filteredCategoriesArchive(): PlanDashboardCategory[] {
      return this.plan.planDashboardCategories.filter((category)=>{
        return category.dashboards.filter((dashboard)=>{
          return dashboard.isArchived
        }).length > 0
      }).sort((a, b) => {
        return a.sortOrder - b.sortOrder
      })
    },
    categoriesOptions(): PlanDashboardCategory[] {
      return this.plan.planDashboardCategories.concat([new PlanDashboardCategory(new ID(0), 'New Category')])
    },
    shouldShowNewCategoryNameField(): boolean {
      return this.newDashboard?.category?.id?.intID === 0
    }
  },
  mounted() {
    this.resetNewDashboardObjects()
  },
  methods: {
    onCategoryNameEditInit(category: PlanDashboardCategory) {
      this.editingCategoryNames.push({
        id: category.id.intID,
        originalName: category.name
      })
    },
    onCategoryNameEditSave(category: PlanDashboardCategory) {
      this.$store.getters.services.dashboards.updateCategory(category)
        .then(
          (updatedCategory) => {
            Vue.prototype.$toast.add({
              severity: AlertMessageSeverity.success,
              summary: 'Category updated.',
              life: 3000,
            })
            this.onCategoryNameEditCancel(category, false)
          }, 
          (error)=>{
            Vue.prototype.$toast.add({
              severity: AlertMessageSeverity.error,
              summary: 'Error reordering categories.',
              detail: error.message,
            })
            this.onCategoryNameEditCancel(category)
          }
        )
    },
    onCategoryNameEditCancel(category: PlanDashboardCategory, shouldResetName = true) {
      if(shouldResetName){
        category.name = this.editingCategoryNames.find((obj) => obj.id === category.id.intID)?.originalName
      }
      this.editingCategoryNames = this.editingCategoryNames.filter((obj) => obj.id !== category.id.intID)
    },
    onCategoryReorder(dropResult, categoriesArray){
      const sortedCategories = applyDrag(categoriesArray, dropResult)
      sortedCategories.forEach((category, index) => {
        category.sortOrder = index
        this.$store.getters.services.dashboards.updateCategory(category).catch((error)=>{
          Vue.prototype.$toast.add({
            severity: AlertMessageSeverity.error,
            summary: 'Error reordering categories.',
            detail: error.message,
          })
        })
      })
    },
    onDashboardDelete(event, dashboard: PlanDashboard) {
      this.$confirm.require({
        acceptLabel: 'YES',
        rejectLabel: 'NO',
        target: event.currentTarget,
        message: 'Are you sure you want to delete this performance data?',
        acceptClass: 'btn-delete-accept',
        accept: () => {
          this.$store.getters.services.dashbnoards.delete(dashboard.id.intID).then(
            ()=>{
              this.$store.dispatch('refreshCurrentPlan')

              Vue.prototype.$toast.add({
                severity: AlertMessageSeverity.success,
                summary: 'Performance data deleted.',
                life: 3000,
              })
            },
            (error)=>{
              Vue.prototype.$toast.add({
                severity: AlertMessageSeverity.error,
                summary:
                  'There was an error deleting the performance data. Please try again.',
              })
            }
          )
        },
        reject: () => {
          //Delete rejected
        },
      })
    },
    onActiveDashboardArchive(event, dashboard: PlanDashboard) {
      dashboard.isArchived = true
      this.$store.getters.services.dashboards.update(dashboard).then(
        (updatedDashboard) => {
          this.$store.dispatch('refreshCurrentPlan')

          Vue.prototype.$toast.add({
            severity: AlertMessageSeverity.success,
            summary: 'Performance data archived.',
            life: 3000,
          })
        },
        (error) => {
          Vue.prototype.$toast.add({
            severity: AlertMessageSeverity.error,
            summary: 'Error archiving performance data.',
            detail: error.message,
          })
        }
      )
    },
    onArchivedDashboardUnarchive(event, dashboard: PlanDashboard) {
      dashboard.isArchived = false
      this.$store.getters.services.dashboards.update(dashboard).then(
        (updatedDashboard) => {
          this.$store.dispatch('refreshCurrentPlan')

          Vue.prototype.$toast.add({
            severity: AlertMessageSeverity.success,
            summary: 'Performance data unarchived.',
            life: 3000,
          })
        },
        (error) => {
          Vue.prototype.$toast.add({
            severity: AlertMessageSeverity.error,
            summary: 'Error unarchiving performance data.',
            detail: error.message,
          })
        }
      )
    },
    onDashboardRowEditInit() {
      // Init row edit
    },
    onDashboardRowEditSave(event) {
      const thisDashboard = PlanDashboard.fromResponseObject({
        id: event.newData.id.intID,
        name: event.newData.name,
        embedHtml: event.newData.embedHtml,
        isArchived: event.newData.isArchived,
        plan: event.newData.plan.intID,
        category: event.newData.category.forRequestObject(),
        orderIndex: event.newData.sortOrder,
      })

      this.$store.getters.services.dashboards.update(thisDashboard).then(
        (updatedDashboard) => {
          this.$store.dispatch('refreshCurrentPlan')

          Vue.prototype.$toast.add({
            severity: AlertMessageSeverity.success,
            summary: 'Performance data updated.',
            life: 3000,
          })
        },
        (error)=>{
          Vue.prototype.$toast.add({
            severity: AlertMessageSeverity.error,
            summary: 'Error updating performance data.',
            detail: error.message,
          })
        })
    },
    onDashboardRowReorder(event) {
      event.value.forEach((dashboard, index) => {
        dashboard.sortOrder = index
        this.$store.getters.services.dashboards.update(dashboard).catch((error)=>{
          Vue.prototype.$toast.add({
            severity: AlertMessageSeverity.error,
            summary: 'Error reordering performance data.',
            detail: error.message,
          })
        })
      })
    },
    handleToggleUseDashboards(value) {
      this.plan.useDashboards = value

      // Persist change
      this.plan.accountId = this.$store.getters.currentAccount.id
      this.$store.getters.services.plans
        .update(this.plan)
        .then((plan) => {
          Vue.prototype.$toast.add({
            severity: AlertMessageSeverity.success,
            summary: 'Plan updated.',
            life: 3000,
          })
          this.$store.dispatch('updateCurrentPlan', plan)
        })
    },
    handleAddDashboard() {
      // Prevent upload if no file is entered
      if(!this.newDashboard.embedHtml){
        Vue.prototype.$toast.add({
          severity: AlertMessageSeverity.warn,
          summary: 'Please add embed HTML.',
        })
        return
      }

      // Prevent upload if new category name is not completed
      if(!this.isSelectedCategory || (this.isSelectedCategory && this.newDashboard.category.id.intID === 0 && this.newCategoryName === '')){
        Vue.prototype.$toast.add({
          severity: AlertMessageSeverity.warn,
          summary: 'Please select or create a category.',
        })
        return
      }

      if(!this.shouldAllowFormInput){
        return
      }

      this.shouldAllowFormInput = false
      this.isSelectedCategory = false

      if(this.newCategoryName !== ''){
        this.newDashboardCategory.name = this.newCategoryName
            
        this.$store.getters.services.dashboards.createCategory(this.newDashboardCategory).then(
          (returnedDashboardCategory) => {
            this.plan.planDashboardCategories.push(returnedDashboardCategory)
            this.newDashboard.category = returnedDashboardCategory

            this.$store.getters.services.dashboards.create(this.newDashboard).then(
              (returnedNewDashboard) => {
                this.plan.dashboards.push(returnedNewDashboard)
                this.resetNewDashboardObjects()
                this.$store.dispatch('refreshCurrentPlan')

                Vue.prototype.$toast.add({
                  severity: AlertMessageSeverity.success,
                  summary: `Performance data created.`,
                  life: 3000,
                })
              },
              (error) => {
                Vue.prototype.$toast.add({
                  severity: AlertMessageSeverity.error,
                  summary: `Error creating performance data.`,
                })
              }
            ).finally(() => {
              this.shouldAllowFormInput = true
            })
          },
          (error) => {
            Vue.prototype.$toast.add({
              severity: AlertMessageSeverity.error,
              summary: `Error creating category.`,
            })
          }
        ).finally(() => {
          this.shouldAllowFormInput = true
        })
      }else{
        this.$store.getters.services.dashboards.create(this.newDashboard).then(
          (returnedNewDashboard) => {
            this.plan.dashboards.push(returnedNewDashboard)
            this.resetNewDashboardObjects()
            this.$store.dispatch('refreshCurrentPlan')

            Vue.prototype.$toast.add({
              severity: AlertMessageSeverity.success,
              summary: `Performance data created.`,
              life: 3000,
            })
          },
          (error) => {
            Vue.prototype.$toast.add({
              severity: AlertMessageSeverity.error,
              summary: `Error creating performance data.`,
            })
          }
        ).finally(() => {
          this.shouldAllowFormInput = true
        })
      }
    },
    resetNewDashboardObjects() {
      this.newDashboard = PlanDashboard.fromResponseObject({})
      this.newDashboard.plan = this.plan.id
      this.newCategoryName = ''
      this.newDashboardCategory = PlanDashboardCategory.fromResponseObject({})
    },
  },
})
</script>