<template>
  <div class="view-wrapper performance-view">
    <ViewHeader :title="$store.getters.currentPlan.name">
    </ViewHeader>
    <ViewMain>
      <div class="p-d-flex">
        <div class="list-column">

          <div class="active-section">
            <template v-for="category in planDashboardCategories">
              <template v-if="category.activeDashboards.length">
                <DataTable 
                  :value="category.activeDashboards"
                  editMode="row"
                  dataKey="id.intID"
                  :key="category.key"
                  class="datatable-active"
                  >
                  <Column field="title" :header="category.name">
                    <template #body="slotProps">
                      {{ slotProps.data.name }}
                    </template>
                  </Column>
                  <Column>
                    <template #body="slotProps">
                      <div class="p-d-flex p-jc-end p-ai-center">
                        <template v-if="slotProps.data.id.intID === currentDashboard.id.intID">
                          <div class="current-dashboard-indicator"></div>
                        </template>
                        <template v-else>
                          <Button 
                            icon="pi pi-arrow-circle-up" 
                            class="p-button-text"
                            @click="setCurrentDashboard(slotProps.data)"
                          ></Button>
                        </template>
                      </div>
                    </template>
                  </Column>
                </DataTable>
              </template>
            </template>
          </div>

          <Panel 
            header="Archive" 
            class="panel-archive"
            :toggleable="true"
            :collapsed="true"
            >
            <template v-for="category in planDashboardCategories">
              <template v-if="category.archivedDashboards.length">
                <DataTable 
                  :value="category.archivedDashboards"
                  editMode="row"
                  dataKey="id.intID"
                  :key="category.key"
                  class="datatable-archived"
                  >
                  <Column field="title" :header="category.name">
                    <template #body="slotProps">
                      {{ slotProps.data.name }}
                    </template>
                  </Column>
                </DataTable>
              </template>
            </template>
          </Panel>
          <p>Looker Studio dashboard - click bottom right corner to enter full screen.</p>
          <p>Google Slide decks - click three stacked dots bottom left to enter full screen.</p>
        </div>
        <div class="dashboard-column">
          <h2 v-if="currentDashboard.name" class="p-mr-2">{{ currentDashboard.name }}</h2>
          <div class="embed-wrapper">
            <ProgressSpinner v-if="shouldShowProgressSpinner" strokeWidth="3" class="dashboard-progress-spinner" />
            <div class="embed-container" v-html="currentDashboard.embedHtml"></div>
          </div>
        </div>
      </div>
    </ViewMain>
  </div>
</template>

<script lang="ts">
import Vue from 'vue'
import ViewHeader from '@/components/ViewHeader.vue'
import ViewMain from '@/components/ViewMain.vue'
import PlanDashboard from '@/models/PlanDashboard';
import DataTable from 'primevue/datatable/DataTable';
import Panel from 'primevue/panel';
import Column from 'primevue/column/Column';
import Button from 'primevue/button';
import InputText from 'primevue/inputtext';
import TextArea from 'primevue/textarea'
import Plan from '@/models/Plan';
import PlanDashboardCategory from '@/models/PlanDashboardCategory';


Vue.component('Button', Button)
Vue.component('InputText', InputText)
Vue.component('TextArea', TextArea)
Vue.component('DataTable', DataTable)
Vue.component('Panel', Panel)

export default Vue.extend({
  name: 'Performance',
  components: {
    ViewHeader,
    ViewMain,
  },
  data: () => {
    return {
      currentDashboard: {} as PlanDashboard,
      shouldShowProgressSpinner: false,
    }
  },
  computed: {
    currentPlan(): Plan {
      return this.$store.getters.currentPlan
    },
    planDashboardCategories(): PlanDashboardCategory[] {
      return this.currentPlan.planDashboardCategories.toSorted((a, b) => a.sortOrder - b.sortOrder)
    },
  },
  mounted: function(){
    this.resetCurrentDashboard()
  },
  methods: {
    resetCurrentDashboard() {
      if(this.planDashboardCategories.length){
        this.planDashboardCategories.every((category) => {
          if(category.activeDashboards.length){
            this.setCurrentDashboard(category.activeDashboards[0])
            return false
          }
        })
      }else{
        this.currentDashboard = {} as PlanDashboard
        this.shouldShowProgressSpinner = false
      }
    },
    setCurrentDashboard(dashboard) {
      this.shouldShowProgressSpinner = true
      this.currentDashboard = dashboard as PlanDashboard
    },
  }
})
</script>

<style lang="scss">
@import '@/styles/_imports.scss';

.performance-view {
  .p-datatable {
    margin-bottom: 3.6rem;

    .p-datatable-thead {
      &> tr {
        &> th {
          padding: 1rem;
          font-size: 1.3rem;
        }
      }
    }
    .p-datatable-tbody {
      &> tr {
        &> td {
          padding: 1rem;
          border: 0;
          font-size: 1.2rem;
        }
      }
    }
  }
}
.panel-archive {
  margin-bottom: 4.6rem;

  .p-panel-header {
    background-color: #999999;

    .p-panel-title {
      font-size: 1.3rem;
    }
  }
  .p-toggleable-content {
    border: 0;

    .p-panel-content {
      padding: 0;
      border: 0;

      .p-datatable-thead {
        margin-bottom: 0;
      }
      .p-datatable-tbody {
        td {
          line-height: 3.2rem;
        }
      }
    }
  }
}
.list-column {
  width: 20%;
  margin-right: 3rem;

  .is-archived {
    color: $nobel;
  }
  &> p {
    color: #999999;
  }
}
.dashboard-column {
  width: 80%;
}
.current-dashboard-indicator {
  width: 2.4rem;
  height: 2.4rem;
  margin: 0.4rem 0;
  border-radius: 50%;
  background-color: #008001;
}
.embed-wrapper {
  position: relative;

  .dashboard-progress-spinner {
    position: absolute;
  }
  .embed-container {
    position: relative;
    z-index: 2;
    border: 0.1rem solid #000;
  }
}
</style>
