import Vue from 'vue'
import Vuex, {Store} from 'vuex'
import AppRouter from '@/AppRouter'
import UserSetup from '@/models/UserSetup'
import AccessToken from '@/models/AccessToken'
import AuthenticationService from '@/services/AuthenticationService'
import InitialResponse from '@/models/InitialResponse'
import Plan from '@/models/Plan'
import Role from '@/models/Role'
import User, {UserMostRecentView} from '@/models/User'
import Account from '@/models/Account'
import AccountsService from '@/services/AccountsService'
import ChannelsService from '@/services/ChannelsService'
import CommentsService from '@/services/CommentsService'
import CustomViewsService from '@/services/CustomViewsService'
import PlansService from '@/services/PlansService'
import FeedbackService from '@/services/FeedbackService'
import TacticsService from '@/services/TacticsService'
import UsersService from '@/services/UsersService'
import TagsService from '@/services/TagsService'
import Subscription from '@/models/Subscription'
import Channel from '@/models/Channel'
import Tactic from '@/models/Tactic'
import axios from 'axios'
import AlertMessage, {AlertMessageSeverity} from '@/models/AlertMessage'
import Tag from '@/models/Tag'
import ID from '@/models/ID'
import TacticType from '@/models/TacticType'
// import TacticsImportMapping from '@/models/TacticsImportMapping' // Archiving TacticImport.vue - JV 04.18.23
import DuplicateSubscriptionEventMapping from '@/models/DuplicateSubscriptionEventMapping'
import UserRole from '@/models/UserRole'
import {rrulestr} from 'rrule'
import {isSameDay, differenceInMinutes, addMinutes} from 'date-fns'
import TacticPlatform from '@/models/TacticPlatform'
import BudgetsService from '@/services/BudgetsService'
import DocumentsService from '@/services/DocumentsService'
import PlanDocumentCategory from '@/models/PlanDocumentCategory'
import PlanDocument from '@/models/PlanDocument'
import TagCategory from '@/models/TagCategory'
import DashboardsService from './services/DashboardsService'

Vue.use(Vuex)
// Vuex.Store.prototype.$toast = Vue.prototype.$toast

export default new Vuex.Store({
  state: {
    services: {
      accounts: new AccountsService(),
      authentication: new AuthenticationService(),
      channels: new ChannelsService(),
      comments: new CommentsService(),
      customViews: new CustomViewsService(),
      feedback: new FeedbackService(),
      plans: new PlansService(),
      tactics: new TacticsService(),
      users: new UsersService(),
      tags: new TagsService(),
      budgets: new BudgetsService(),
      documents: new DocumentsService(),
      dashboards: new DashboardsService(),
    },

    // Initialization and auth
    isAppInitialized: false as boolean,
    isUIBlocked: false as boolean,
    isUserLoggedIn: false as boolean,
    accessToken: {} as AccessToken,
    refreshAccessTokenTimer: {},
    shouldFlushedLocalstorage: 1679670964013,
    routeAccountIntId: 0 as number,
    localDevRefreshToken: '' as string, // In local dev environment, set this to value of refreshToken from set-cookie header in /authenticate response in order to maintain application state between browser refreshes

    // Global UI selections
    routeHistory: [] as {}[],
    currentDateRange: [] as Date[],
    monthViewDateRange: [] as Date[],
    dayViewDateRange: [] as Date[],
    rangeViewsDateRange: [] as Date[],
    initiativesDateRange: [] as Date[],
    recentDateRangesDictionary: {} as any,
    selectedChannelIntID: 0 as number,
    selectedTypeIntID: 0 as number,
    selectedPlatformIntID: 0 as number,
    isGlobalNavCollapsed: false as boolean, // Manages global nav state and relevant styles in other views
    isFeedbackModalOpen: false as boolean, // Manages feedback UI visibility
    isPrintDialogOpen: false as boolean, // Manages print layout dependencies throughout app

    // Lists of all objects of a certain type
    allAccounts: [] as Account[],
    allRoles: [] as Role[],

    // Currently active objects
    currentUserIntID: 0 as number,
    currentAccountIntID: 0 as number,
    currentPlanIntID: 0 as number,
    currentTacticIntID: 0 as number,

    // Filter state
    activeFiltersCount: '' as string, // badge expecting string
    visibleNestedPlansIntIds: [] as number[],
    selectedTacticTypeIntIds: [] as number[],
    selectedInitiativesIntIds: [] as number[],
    selectedTagsIntIds: [] as number[],
    selectedSubscriptionsIntIds: [] as number[],
    isLeadPlanVisible: false as boolean,
    isNestedPlanFilterActive: false as boolean,
    isChannelFilterActive: false as boolean,
    isInitiativeFilterActive: false as boolean,
    isTagFilterActive: false as boolean,
    isSubscriptionFilterActive: false as boolean,

    // Archiving TacticImport.vue - JV 04.18.23
    // Tactic import
    // currentTacticMapping: {} as TacticsImportMapping,
    // currentImportedTacticRows: [] as object[],

    // Dictionaries
    initiativeTypeNamesDict: {
      campaign: 'campaign',
      promotion: 'promotion',
      keyDate: 'key date',
      monthlyTheme: 'monthly theme',
    } as {},
    socialTacticPlatformNamesDict: [
      'Twitter',
      'Facebook',
      'Instagram',
      'Pinterest',
      'LinkedIn',
      'Snapchat',
      'WhatsApp',
      'TikTok',
      'Tumblr',
      'Reddit',
    ] as string[],
    defaultChannelNamesSortOrder: [
      'Website',
      'Email',
      'Social Media',
      'Signage',
      'Collateral',
      'Direct Mail',
      'PR',
      'Advertising Assets',
      'Paid Search',
      'Social Advertising',
      'Display',
      'Print',
      'Out-of-Home',
      'Radio',
      'Cross Channel Video',
    ] as string[],
    annumTeamUserEmailAddressDict: [
      'annum@annumplanning.com',
      'jon@informedfunction.com',
      'shane@partandpixel.com',
    ] as string[],
    monthlyFocusTypeName: 'monthly focus',
    tutorialUrlsDict: {
      accountOwner:
        'https://www.annumplanning.com/resources/guides/account-owner-tutorial/',
      planAdmin:
        'https://www.annumplanning.com/resources/guides/plan-admin-tutorial/',
      contributor:
        'https://www.annumplanning.com/resources/guides/contributor-tutorial/',
      viewer: 'https://www.annumplanning.com/resources/guides/viewer-tutorial/',
    },
  },

  getters: {
    // Initialization and auth
    services: (state) => state.services,
    isAppInitialized: (state) => state.isAppInitialized,
    isUIBlocked: (state) => state.isUIBlocked,
    isUserLoggedIn: (state) => state.isUserLoggedIn,
    accessToken: (state) => state.accessToken,
    mostRecentCoreView: (state, getters) => getters.currentUser.mostRecentView,
    routeAccountIntId: (state) => state.routeAccountIntId,

    // Global UI selections
    routeHistory: (state) => {
      return state.routeHistory
    },
    currentDateRange: (state) => {
      return state.currentDateRange
    },
    monthViewDateRange: (state) => {
      return state.monthViewDateRange
    },
    dayViewDateRange: (state) => {
      return state.dayViewDateRange
    },
    rangeViewsDateRange: (state) => {
      return state.rangeViewsDateRange
    },
    initiativesDateRange: (state) => {
      return state.initiativesDateRange
    },
    datePickerYearRange: () => {
      const now = new Date()
      return (
        String(now.getFullYear() - 10) + ':' + String(now.getFullYear() + 10)
      )
    },
    selectedChannel: (state, getters) => {
      const thisChannel = getters.currentChannels.find(
        (channel) => channel.id.intID === state.selectedChannelIntID
      )
      return thisChannel ? thisChannel : new Channel()
    },
    selectedType: (state, getters) => {
      const thisType = getters.currentTacticTypes.find(
        (type) => type.id.intID === state.selectedTypeIntID
      )
      return thisType ? thisType : new TacticType()
    },
    selectedPlatform: (state, getters) => {
      const thisPlatform = getters.currentTacticPlatforms.find(
        (platform) => platform.id.intID === state.selectedPlatformIntID
      )
      return thisPlatform ? thisPlatform : new TacticPlatform()
    },
    isGlobalNavCollapsed: (state) => state.isGlobalNavCollapsed,
    isFeedbackModalOpen: (state) => state.isFeedbackModalOpen,
    isPrintDialogOpen: (state) => state.isPrintDialogOpen,

    // Permissions and roles
    currentAccountPermissionLevel: (state, getters) => {
      // TODO: temporary fix for identifying super users - implement internal admin UI in the future
      if (getters.currentUser.isSuperUser) {
        if (
          getters.currentAccount?.id?.intID === 1 &&
          getters.currentUser?.id?.intID === 3
        ) {
          return Role.LEVEL_ACCOUNT_OWNER
        }
        return Role.LEVEL_ACCOUNT_ADMIN
      }
      if (
        getters.currentUser?.id?.intID ===
        getters.currentAccount?.ownerId?.intID
      ) {
        return Role.LEVEL_ACCOUNT_OWNER
      }
      let lowestAccountPermissionLevel = 1000000
      getters.currentAccount?.userRoles?.forEach((userRole) => {
        if (userRole?.userId?.intID === getters.currentUser?.id?.intID) {
          if (
            userRole.roleId.intID === 5 &&
            lowestAccountPermissionLevel > Role.LEVEL_CONTRIBUTOR
          ) {
            lowestAccountPermissionLevel = Role.LEVEL_ACCOUNT_ADMIN
          } else if (
            userRole.roleId.intID === 4 &&
            lowestAccountPermissionLevel > Role.LEVEL_PLAN_ADMIN
          ) {
            lowestAccountPermissionLevel = Role.LEVEL_PLAN_ADMIN
          } else if (
            userRole.roleId.intID === 2 &&
            lowestAccountPermissionLevel > Role.LEVEL_ACCOUNT_ADMIN
          ) {
            lowestAccountPermissionLevel = Role.LEVEL_ACCOUNT_ADMIN
          } else if (
            userRole.roleId.intID === 1 &&
            lowestAccountPermissionLevel > Role.LEVEL_ACCOUNT_OWNER
          ) {
            lowestAccountPermissionLevel = Role.LEVEL_ACCOUNT_OWNER
          }
        }
      })
      return lowestAccountPermissionLevel < 1000000
        ? lowestAccountPermissionLevel
        : Role.LEVEL_VIEWER
    },
    currentPlanPermissionLevel: (state, getters) => {
      return getters.getPermissionLevelForPlanId(getters.currentPlan.id)
    },
    getPermissionLevelForPlanId: (state, getters) => (planId: ID) => {
      if (!planId) {
        return getters.currentAccountPermissionLevel
      }
      const thisUserRole = getters.getUserRoleforPlanId(planId)
      if (thisUserRole && thisUserRole.roleId) {
        return getters.getPermissionLevelForRoleId(thisUserRole.roleId)
      }
      return getters.currentAccountPermissionLevel
    },
    currentUserRole: (state, getters) => {
      return (
        getters.getUserRoleforPlanId(getters.currentPlan.id) || new UserRole()
      )
    },
    getUserRoleforPlanId: (state, getters) => (planId: ID) => {
      const thisPlan = getters.allPlans.find(
        (plan) => plan.id.intID == planId.intID
      )
      if (thisPlan && thisPlan.userRoles) {
        const planUserRolesForCurrentUser = thisPlan.userRoles.filter(
          (userRole) => userRole.userId.intID == getters.currentUser.id.intID
        )
        if (planUserRolesForCurrentUser.length === 1) {
          return planUserRolesForCurrentUser[0]
        } else if (planUserRolesForCurrentUser.length > 1) {
          let lowestPermissionLevel = 1000000
          let lowestUserRole = null
          planUserRolesForCurrentUser.forEach((userRole) => {
            const thisPermissionLevel = getters.getPermissionLevelForRoleId(
              userRole.id
            )
            if (thisPermissionLevel < lowestPermissionLevel) {
              lowestPermissionLevel = thisPermissionLevel
              lowestUserRole = userRole
            }
          })
          return lowestUserRole
        } else {
          return null
        }
      }
      return null
    },
    getPermissionLevelForRoleId: (state, getters) => (roleId: ID) => {
      if (roleId.intID !== 0) {
        const thisRole = getters.allRoles.find(
          (role) => role.id.intID == roleId.intID
        )
        if (thisRole) {
          return thisRole.level
        }
      }
      return null
    },
    isUserAnnumTeamMember: (state, getters) =>
      state.annumTeamUserEmailAddressDict.includes(
        getters.currentUser.emailAddress
      ),

    // Lists of all objects of a certain type
    currentSubscriptions: (state, getters) =>
      getters.allSubscriptions.filter((subscription) =>
        getters.currentPlan.subscriptionIds
          .map((id) => id.intID)
          .includes(subscription.id.intID)
      ),
    allSubscriptions: (state, getters) => getters.currentAccount.subscriptions,
    allAccounts: (state) => {
      const usedIds = [] as number[] // API is returning multiple instances of the same account, this is in place to filter out duplicates
      const returnArray = state.allAccounts.filter((account) => {
        if (!usedIds.includes(account.id.intID)) {
          usedIds.push(account.id.intID)
          return account
        }
      })
      return returnArray.sort((a, b) => {
        const textA = a.contactCompanyName.toLowerCase()
        const textB = b.contactCompanyName.toLowerCase()
        return textA < textB ? -1 : textA > textB ? 1 : 0
      })
    },
    allPlans: (state, getters) => {
      if (getters.currentAccount && getters.currentAccount.plans) {
        const currentPlans = getters.currentAccount.plans.filter(
          (plan) => !plan.parentId
        )
        // add nested plans back in array just behind their Lead Plan
        getters.currentAccount.plans
          .filter((plan) => !!plan.parentId)
          .forEach((nestedPlan) => {
            // find the index of their Lead Plan
            const leadPlanIndex = currentPlans.findIndex(
              (leadPlan) => leadPlan.id.intID == nestedPlan.parentId.intID
            )
            // insert just after
            currentPlans.splice(leadPlanIndex + 1, 0, nestedPlan)
          })

        return currentPlans
      }
      return []
    },
    allRoles: (state) => state.allRoles,

    // Currently active objects
    allAccountUsers: (state, getters) => getters.currentAccount.users,
    isCurrentUserSet: (state, getters) =>
      getters.currentUser.id && getters.currentUser.id.intID != 0
        ? true
        : false,
    currentUserIntID: (state) => state.currentUserIntID,
    currentUser: (state, getters) => {
      const thisUser = getters.allAccountUsers.find(
        (user) => user.id.intID === getters.currentUserIntID
      )
      return thisUser ? thisUser : new User()
    },
    currentAccountUserRoles: (state, getters) => {
      const uniqueUserIntIds = [] as number[]
      const uniqueUserRoles = [] as UserRole[]
      getters.currentAccount.userRoles.forEach((userRole) => {
        // TODO: conditions for user ID and account ID temporary fix to hide patty's user from lists of users on customer accounts
        if (
          !uniqueUserIntIds.includes(userRole.userId.intID) &&
          (userRole.userId.intID !== 3 ||
            (userRole.userId.intID === 3 &&
              getters.currentAccount.id.intID === 1))
        ) {
          uniqueUserRoles.push(userRole)
          uniqueUserIntIds.push(userRole.userId.intID)
        }
      })
      return uniqueUserRoles
    },
    currentPlanUserRoles: (state, getters) =>
      getters.currentPlan.userRoles.filter(
        (userRole) => userRole.planId.intID == getters.currentPlan.id.intID
      ),
    currentAccountIntID: (state) => state.currentAccountIntID,
    currentAccount: (state, getters) => {
      const thisAccount = getters.allAccounts.find(
        (account) => account.id.intID === getters.currentAccountIntID
      )
      return thisAccount ? thisAccount : new Account()
    },
    currentPlanIntID: (state) => state.currentPlanIntID,
    currentPlan: (state, getters) => {
      let returnValue: Plan = new Plan()
      if (getters.currentAccount && getters.currentAccount.plans) {
        returnValue = getters.currentAccount.plans.find(
          (plan) => plan.id.intID == getters.currentPlanIntID
        )
      }
      return returnValue ? returnValue : new Plan()
    },
    currentChannels: (state, getters) => {
      const returnArray = [] as Channel[]
      if (getters.currentPlan.channels) {
        getters.currentPlan.channels.filter((channel) => {
          if (channel.name !== Channel.CHANNEL_NAME_INITIATIVES) {
            returnArray.push(channel)
          }
        })
      }
      //Sort channels by specific order - default channels according to defaultChannelNamesSortOrder first, then any user-generated channels
      const sortByObject = state.defaultChannelNamesSortOrder.reduce(
        (a, c, i) => {
          a[c] = i
          return a
        },
        {}
      )
      return returnArray
        .sort((a, b) => sortByObject[a.name] - sortByObject[b.name])
        .sort((a, b) => {
          if (a.orderIndex === b.orderIndex) {
            return 0
          }
          return a.orderIndex > b.orderIndex ? 1 : -1
        })
    },
    currentTacticTypes: (state, getters) => {
      const returnArray = [] as TacticType[]
      getters.currentChannels.forEach((channel) => {
        returnArray.push(...channel.tacticTypes)
      })
      return returnArray
    },
    currentTacticPlatforms: (state, getters) => {
      const returnArray = [] as TacticPlatform[]
      getters.currentTacticTypes.forEach((type) => {
        returnArray.push(...type.tacticPlatforms)
      })
      return [
        ...new Map(returnArray.map((item) => [item.id.intID, item])).values(),
      ]
    },
    currentInitiatives: (state, getters) => {
      const returnArray = [] as Tactic[]

      if (getters.currentPlan.channels) {
        getters.currentPlan.channels.forEach((channel) => {
          channel.tacticTypes.forEach((type) => {
            if (channel.name == Channel.CHANNEL_NAME_INITIATIVES) {
              type.tactics.forEach((tactic) => {
                returnArray.push(tactic)
              })
            }
          })
        })
      }
      return returnArray
    },
    allInitiativesInCategories: (state, getters) => {
      const returnArray = [] as TacticType[]
      getters.currentPlan.consolidatedInitiativeChannel.tacticTypes.forEach((type)=>{
        returnArray.push({...type})
      })
      return returnArray.sort((a,b) => a.orderIndex - b.orderIndex )
    },
    currentTags: (state, getters) => getters.currentPlan.tags,
    currentPlanTagCategories: (state, getters) => {
      // Create categories
      const categories: TagCategory[] = []
      getters.currentPlan.tags.forEach((tag) => {
        const categoryIndex = categories.findIndex(
          (cat) => cat.name === tag.type
        )
        if (categoryIndex === -1) {
          categories.push(new TagCategory(categories.length + 1, tag.type))
        }
      })
      // Add tags to categories
      getters.currentPlan.tags.forEach((tag) => {
        const categoryIndex = categories.findIndex(
          (cat) => cat.name === tag.type
        )
        categories[categoryIndex === -1 ? 3 : categoryIndex].tags.push(tag)
      })
      // Sort categories alphabetically
      categories.sort((a, b) => {
        if (a.name === b.name) {
          return 0
        }
        return a.name > b.name ? 1 : -1
      })
      // Sort tags within categories according to orderIndex
      categories.forEach((tagCat) => {
        tagCat.tags.sort((a, b) => {
          if (a.orderIndex === b.orderIndex) {
            return 0
          }
          return a.orderIndex > b.orderIndex ? 1 : -1
        })
      })
      return categories
    },
    currentTacticIntID: (state) => state.currentTacticIntID,
    currentTactic: (state, getters) => {
      let returnValue: Tactic = new Tactic()
      if (getters.currentPlan && getters.currentPlan.tactics) {
        returnValue = getters.currentPlan.tactics.find(
          (tactic) => tactic.id.intID == getters.currentTacticIntID
        )
      }
      return returnValue ? returnValue : new Tactic()
    },

    // Filter state
    activeFiltersCount: (state) => state.activeFiltersCount,
    isLeadPlanVisible: (state) => state.isLeadPlanVisible,
    visibleNestedPlansIntIds: (state) => state.visibleNestedPlansIntIds,
    isChannelFilterActive: (state) => state.isChannelFilterActive,
    isInitiativeFilterActive: (state) => state.isInitiativeFilterActive,
    isTagFilterActive: (state) => state.isTagFilterActive,
    isSubscriptionFilterActive: (state) => state.isSubscriptionFilterActive,
    selectedChannels: (state, getters) => {
      return getters.currentChannels.filter((channel) => 
        channel.tacticTypes.filter((type) => state.selectedTacticTypeIntIds.includes(type.id.intID)).length ? true : false
      )
    },
    selectedTacticTypes: (state, getters) => {
      return getters.currentTacticTypes.filter((type) =>
        state.selectedTacticTypeIntIds.includes(type.id.intID)
      )
    },
    selectedInitiatives: (state, getters) => {
      return getters.currentInitiatives.filter((tactic) => {
        if (
          state.selectedInitiativesIntIds.includes(tactic.id.intID) 
          // Removing logic for filtering out initiatives from filtered out lead/nested plans per ticket 918 - JV 10.09.23
          // && ((!tactic.isLead && !tactic.isNested) ||
          //   (tactic.isLead && getters.isLeadPlanVisible) ||
          //   (tactic.isNested &&
          //     getters.visibleNestedPlansIntIds?.find(
          //       (planIntId) => planIntId === tactic.planId.intID
          //     )))
        ) {
          return true
        }
        return false
      })
    },
    selectedInitiativesInCategories: (state, getters) => {
      const returnArray = [] as TacticType[]
      getters.currentPlan.consolidatedInitiativeChannel.tacticTypes.forEach((type)=>{
        const thisType = {...type, tactics: [] as Tactic[]}
        getters.selectedInitiatives.forEach((tactic)=>{
          if((tactic.tacticTypeId.intID === type.id.intID || tactic.tacticTypeName === type.name)){
            thisType.tactics.push(tactic)
          }
        })
        returnArray.push(thisType)
      })
      return returnArray.sort((a,b) => a.orderIndex - b.orderIndex )
    },
    selectedTags: (state, getters) => {
      return getters.currentTags.filter((tag) =>
        state.selectedTagsIntIds.includes(tag.id.intID)
      )
    },
    selectedSubscriptions: (state, getters) => {
      return getters.currentSubscriptions.filter((subscription) =>
        state.selectedSubscriptionsIntIds.includes(subscription.id.intID)
      )
    },
    deselectedInitiatives: (state, getters) =>
      getters.currentInitiatives.filter(
        (tactic) =>
          !getters.selectedInitiatives.filter(
            (selectedTactic) => selectedTactic.id.intID == tactic.id.intID
          ).length
      ),
    deselectedTags: (state, getters) =>
      getters.currentTags.filter(
        (tag) =>
          !getters.selectedTags.filter(
            (selectedTag) => selectedTag.id.intID == tag.id.intID
          ).length
      ),

    // Dictionaries
    paidChannelNames: (state, getters) => {
      return getters.currentChannels.map((channel) => {
        if (channel.isPaidMedia) {
          return channel.name.toLowerCase()
        }
      })
    },
    initiativeTypeNamesDict: (state) => state.initiativeTypeNamesDict,
    socialTacticPlatformNamesDict: (state) =>
      state.socialTacticPlatformNamesDict,
    monthlyFocusTypeName: (state) => state.monthlyFocusTypeName,
    tutorialUrlsDict: (state) => state.tutorialUrlsDict,

    // Archiving TacticImport.vue - JV 04.18.23
    // Tactic mapping
    // currentTacticMapping: (state) => state.currentTacticMapping,
    // currentImportedTacticRows: (state) => state.currentImportedTacticRows,

    // Query string helpers
    currentDateStartQueryString: (state) => {
      if (!state.currentDateRange[0]) {
        return ''
      }
      return (
        state.currentDateRange[0].getMonth() +
        1 +
        '-' +
        state.currentDateRange[0].getDate() +
        '-' +
        state.currentDateRange[0].getFullYear()
      )
    },
    currentDateEndQueryString: (state) => {
      if (!state.currentDateRange[1]) {
        return ''
      }
      return (
        state.currentDateRange[1].getMonth() +
        1 +
        '-' +
        state.currentDateRange[1].getDate() +
        '-' +
        state.currentDateRange[1].getFullYear()
      )
    },
    isLeadPlanVisibleQueryString: (state, getters) => {
      return getters.isLeadPlanVisible ? 'true' : 'false'
    },
    visibleNestedPlansQueryString: (state, getters) => {
      let outputString = ''
      getters.visibleNestedPlansIntIds.forEach((element) => {
        outputString += element + ','
      })
      return outputString.slice(0, outputString.length - 1)
    },
    selectedTacticTypesQueryString: (state, getters) => {
      let outputString = ''
      getters.selectedTacticTypes.forEach((element) => {
        outputString += element.id.intID + ','
      })
      return outputString.slice(0, outputString.length - 1)
    },
    selectedInitiativesQueryString: (state, getters) => {
      let outputString = ''
      getters.selectedInitiatives.forEach((element) => {
        outputString += element.id.intID + ','
      })
      return outputString.slice(0, outputString.length - 1)
    },
    selectedTagsQueryString: (state, getters) => {
      let outputString = ''
      getters.selectedTags.forEach((element) => {
        outputString += element.id.intID + ','
      })
      return outputString.slice(0, outputString.length - 1)
    },
    selectedSubscriptionsQueryString: (state, getters) => {
      let outputString = ''
      getters.selectedSubscriptions.forEach((element) => {
        outputString += element.id.intID + ','
      })
      return outputString.slice(0, outputString.length - 1)
    },
  },

  mutations: {
    addRouteToRouteHistory: function(state, newRoute: {}){
      if(!state.routeHistory || !state.routeHistory.length){
        state.routeHistory = [{...newRoute, matched: null, order: 0}]
      }else{
        state.routeHistory.push({...newRoute, matched: null, order: state.routeHistory.length})
      }
    },
    updateIsUserLoggedIn: function (state, newValue: boolean) {
      Vue.set(state, 'isUserLoggedIn', newValue)
    },
    updateIsAppInitialized: function (state, newValue: boolean) {
      Vue.set(state, 'isAppInitialized', newValue)
    },
    updateIsUIBlocked: function (state, newValue: boolean) {
      Vue.set(state, 'isUIBlocked', newValue)
    },
    updateIsGlobalNavCollapsed: function (state, newValue: boolean) {
      Vue.set(state, 'isGlobalNavCollapsed', newValue)
    },
    updateIsPrintDialogOpen: function (state, newValue: boolean) {
      Vue.set(state, 'isPrintDialogOpen', newValue)
    },
    updateIsLeadPlanVisible: function (state, newValue: boolean) {
      Vue.set(state, 'isLeadPlanVisible', newValue)
    },
    updateVisibleNestedPlansIntIds: function (state, newPlanIds: number[]) {
      Vue.set(state, 'visibleNestedPlansIntIds', newPlanIds)
    },
    updateSelectedTacticTypes: function (state, newTypes: TacticType[]) {
      Vue.set(
        state,
        'selectedTacticTypeIntIds',
        newTypes.map((type) => type.id.intID)
      )
    },
    updateSelectedInitiatives: function (state, newInitiatives: Tactic[]) {
      Vue.set(
        state,
        'selectedInitiativesIntIds',
        newInitiatives.map((tactic) => tactic.id.intID)
      )
    },
    updateSelectedTags: function (state, newTags: Tag[]) {
      Vue.set(
        state,
        'selectedTagsIntIds',
        newTags.map((tag) => tag.id.intID)
      )
    },
    updateSelectedSubscriptions: function (
      state,
      newSubscriptions: Subscription[]
    ) {
      Vue.set(
        state,
        'selectedSubscriptionsIntIds',
        newSubscriptions.map((subscription) => subscription.id.intID)
      )
    },

    isNestedPlanFilterActive: function (state, newValue: boolean) {
      Vue.set(state, 'isNestedPlanFilterActive', newValue)
    },
    isChannelFilterActive: function (state, newValue: boolean) {
      Vue.set(state, 'isChannelFilterActive', newValue)
    },
    isInitiativeFilterActive: function (state, newValue: boolean) {
      Vue.set(state, 'isInitiativeFilterActive', newValue)
    },
    isTagFilterActive: function (state, newValue: boolean) {
      Vue.set(state, 'isTagFilterActive', newValue)
    },
    isSubscriptionFilterActive: function (state, newValue: boolean) {
      Vue.set(state, 'isSubscriptionFilterActive', newValue)
    },

    updateCurrentPlan: function (state, newPlan: Plan) {
      Vue.set(state, 'currentPlanIntID', newPlan.id.intID)
    },
    updateCurrentPlanIntID: function (state, intID: number) {
      Vue.set(state, 'currentPlanIntID', intID)
    },
    updateCurrentAccount: function (state, newAccount: Account) {
      if (state.allAccounts.length === 0) {
        state.allAccounts.push(newAccount)
      } else {
        state.allAccounts = state.allAccounts.map((account) => {
          if (account.id.intID === newAccount.id.intID) {
            return newAccount
          }
          return account
        })
      }
      Vue.set(state, 'currentAccountIntID', newAccount.id.intID)
      Vue.set(state, 'routeAccountIntId', newAccount.id.intID)
      window.localStorage.setItem('act', newAccount.id.intID.toString())
    },
    setCurrentTacticIntID: function (state, tacticIntID: number) {
      Vue.set(state, 'currentTacticIntID', tacticIntID)
    },

    updateSelectedChannel: function (state, channel: Channel) {
      Vue.set(state, 'selectedChannelIntID', channel?.id?.intID)
    },
    updateSelectedType: function (state, type: TacticType) {
      Vue.set(state, 'selectedTypeIntID', type?.id?.intID)
    },
    updateSelectedPlatform: function (state, platform: TacticPlatform) {
      Vue.set(state, 'selectedPlatformIntID', platform?.id?.intID)
    },

    // Archiving TacticImport.vue - JV 04.18.23
    // updateCurrentTacticMapping: function (
    //   state,
    //   mapping: TacticsImportMapping
    // ) {
    //   Vue.set(state, 'currentTacticMapping', mapping)
    // },
    // updateCurrentImportedTacticRows: function (state, rows: object[]) {
    //   Vue.set(state, 'currentImportedTacticRows', rows)
    // },

    updateIsFeedbackModalOpen: function (state, newValue: boolean) {
      Vue.set(state, 'isFeedbackModalOpen', newValue)
    },

    updateRouteAccountIntId: function (state, newIntId: number) {
      Vue.set(state, 'routeAccountIntId', newIntId)
    },
  },

  actions: {
    //-----------------------------------------------//
    //Bootstrapping and authentication flow
    //-----------------------------------------------//
    initApp: function (context, payload) {
      return new Promise<void>((resolve, reject) => {
        //Set API comms defaults
        // axios.defaults.baseURL = LOCAL: Check the vue.config.js for parameters.
        // axios.defaults.baseURL = STANGA DEV: 'https://annum.sn77.net/api/' //Stanga QA - test creds: eugen@test.com / eugentest | jon+test01@informedfunction.com / pass
        // axios.defaults.baseURL = ANNUM STAGE: 'https://stagingapi.annumapp.com/api/' //Staging - test creds: shane@partandpixel.com / pass | jon@informedfunction.com / pass | annum@annumplanning.com / pass
        // axios.defaults.baseURL = ANNUM PROD: 'https://api.annumapp.com/api/' //Prod - test creds: ? / ?
        axios.defaults.baseURL = process.env.VUE_APP_API_BASE_URL
        axios.defaults.timeout = 100000
        axios.defaults.headers.common['Accept'] = 'application/json'
        axios.defaults.headers.common['Content-Type'] = 'application/json'

        // Flush localstorage if needed
        const lastFlushedLocalstorage =
          window.localStorage.getItem('last-flushed')
        if (lastFlushedLocalstorage) {
          const lastFlushedDate = new Date(Number(lastFlushedLocalstorage))
          const shouldFlushDate = new Date(
            context.state.shouldFlushedLocalstorage
          )
          if (shouldFlushDate > lastFlushedDate) {
            const cachedLgo = window.localStorage.getItem('lgo')
            const cachedAct = window.localStorage.getItem('act')
            window.localStorage.clear()
            window.localStorage.setItem('last-flushed', Date.now().toString())
            if (cachedLgo) {
              window.localStorage.setItem('lgo', cachedLgo)
            }
            if (cachedAct) {
              window.localStorage.setItem('act', cachedAct)
            }
          }
        } else {
          window.localStorage.setItem('last-flushed', Date.now().toString())
        }

        // Set refreshToken to maintain application state between browser refreshes in local dev environment
        if(context.state.localDevRefreshToken !== ''){
          Vue['cookie'].set('refreshToken', context.state.localDevRefreshToken)
        }

        // Check for logged in/out state
        if (
          window.localStorage.getItem('lgo') === '0' &&
          Vue['cookie'].get('refreshToken')
        ) {
          context.dispatch('refreshAccessToken').then(
            () => {
              // Load plan if plan ID is present in current route
              if(payload?.currentRoute?.params.planId){
                context.dispatch('updateCurrentPlanById', new ID(Number(payload.currentRoute.params.planId))).then(
                  ()=>{
                    context.commit('updateIsAppInitialized', true)
                    resolve()
                  }, 
                  (error)=>{
                    context.commit('updateIsAppInitialized', true)
                    reject(error)
                  }
                )
              }else{
                context.commit('updateIsAppInitialized', true)
                resolve()
              }
            },
            (error) => {
              context.commit('updateIsAppInitialized', true)
              reject(error)
            }
          )
        } else {
          window.localStorage.setItem('lgo', '1')
          context.commit('updateIsUserLoggedIn', false)
          context.commit('updateIsAppInitialized', true)
          resolve()
        }
      })
    },
    refreshAccessToken: function (context) {
      return new Promise<void>((resolve, reject) => {
        context.state.services.authentication.refreshAccessToken().then(
          (response) => {
            context.dispatch('parseInitialResponse', response).then(
              () => {
                context.commit('updateIsUserLoggedIn', true)
                context.dispatch('startRefreshAccessTokenTimer')
                resolve()
              },
              (error) => {
                context.commit('updateIsUserLoggedIn', false)
                reject(error)
              }
            )
          },
          (error) => {
            context.commit('updateIsUserLoggedIn', false)
            reject(error)
          }
        )
      })
    },
    startRefreshAccessTokenTimer: function (context) {
      context.state.refreshAccessTokenTimer = setTimeout(function () {
        context.dispatch('refreshAccessToken')
      }, 86400000)
    },
    checkLoggedInStatus: function (context) {
      const loggedInStatus =
        window.localStorage.getItem('lgo') === '0' ? true : false
      context.commit('updateIsUserLoggedIn', loggedInStatus)
      return loggedInStatus
    },
    login: function (context, userData: UserSetup) {
      return new Promise<void>((resolve, reject) => {
        context.state.services.authentication.login(userData).then(
          (initialResponseObj) => {
            context
              .dispatch('logInWithInitialResponse', initialResponseObj)
              .then(
                () => {
                  resolve()
                },
                (error) => {
                  console.warn('AppStore - login: Error from logInWithInitialResponse.')
                  reject(error)
                }
              )
          },
          (error) => {
            console.warn('AppStore - login: Error from /authenticate endpoint.')
            reject(error)
          }
        )
      })
    },
    logInWithInitialResponse: function (context, initialResponseObj) {
      return new Promise<void>((resolve, reject) => {
        context.dispatch('parseInitialResponse', initialResponseObj).then(
          () => {
            context.commit('updateIsUserLoggedIn', true)
            context.dispatch('startRefreshAccessTokenTimer')
            resolve()
          },
          (error) => {
            console.warn('AppStore - logInWithInitialResponse: Error from parseInitialResponse.')
            reject(error)
          }
        )
      })
    },
    logout: function (context) {
      context.state.services.authentication.logout()
      context.commit('updateIsUserLoggedIn', false)
      context.commit('updateRouteAccountIntId', 0)
      context.commit('updateCurrentPlanIntID', 0)
      Vue['cookie'].set('refreshToken', '', {expires: Date.now()})
      window.localStorage.removeItem('act')
    },
    switchAccount: function (context, accountIntId) {
      return new Promise<void>((resolve, reject) => {
        // Request full account object
        context.getters.services.accounts.get([accountIntId]).then(
          (response) => {
            context.commit('updateCurrentAccount', response)
            resolve()
          },
          (error) => {
            Vue.prototype.$toast.add(
              new AlertMessage(AlertMessageSeverity.error, error)
            )
            reject()
          }
        )
      })
    },
    parseInitialResponse: function (
      context,
      newInitialResponse: InitialResponse
    ) {
      return new Promise<void>((resolve, reject) => {
        // Set access token
        axios.defaults.headers.common[
          'Authorization'
        ] = `Bearer ${newInitialResponse.accessToken.token}`
        Vue.set(context.state, 'accessToken', newInitialResponse.accessToken)

        // Update user and roles from initial response data
        Vue.set(
          context.state,
          'currentUserIntID',
          newInitialResponse.user.id.intID
        )
        Vue.set(context.state, 'allRoles', newInitialResponse.roles)

        // Populate allAccounts with account name/ID objects from logged in user
        Vue.set(context.state, 'allAccounts', newInitialResponse.user.accounts)

        //Set current date range to today's date
        context.dispatch('setAllDateRanges')

        // Return error message if no accounts are available
        if (!newInitialResponse.user.accountIds.length) {
          console.warn('AppStore - parseInitialResponse: No accounts available for user.')
          reject('No accounts available for user.')
        }

        // Determine which account to load
        let accountIntIdToLoad: number =
          newInitialResponse.user.accountIds[0].intID
        if (
          context.getters.routeAccountIntId !== 0 &&
          newInitialResponse.user.accountIds.filter(
            (id) => id.intID === context.getters.routeAccountIntId
          ).length
        ) {
          // Set account from ID in route
          accountIntIdToLoad = context.getters.routeAccountIntId
        } else if (
          window.localStorage.getItem('act') !== '' &&
          newInitialResponse.user.accountIds.filter(
            (id) => id.intID === Number(window.localStorage.getItem('act'))
          ).length
        ) {
          // Restore previous plan selection from localstorage
          accountIntIdToLoad = Number(window.localStorage.getItem('act'))
        }

        // Request full account object
        context.dispatch('switchAccount', accountIntIdToLoad).then(
          () => {
            // Update logged in user's object in Account with rolesObserved data from initial response object
            context.getters.currentUser.rolesObserved = [...newInitialResponse.user.rolesObserved]
            resolve()
          },
          (error) => {
            console.warn('AppStore - parseInitialResponse: Error from switchAccount.')
            reject(error)
          }
        )
      })
    },

    //-----------------------------------------------//
    // Update and refresh plan data
    //-----------------------------------------------//
    updateCurrentPlan: function (context, newPlan: Plan) {
      return new Promise<void>((resolve, reject) => {
        //Update Plan object

        context.commit('updateCurrentPlan', newPlan)

        // Set date ranges if recent values exist for the plan
        if(context.state.recentDateRangesDictionary[context.getters.currentPlanIntID]){
          context.dispatch('restoreAllDateRangesForCurrentPlan')
        }else{
          context.dispatch('setAllDateRanges')
        }

        // Set up tactic references
        context.dispatch('populateFrontEndPlanObjects')

        //Update most recent plan on User
        context.getters.currentUser.mostRecentPlanId = newPlan.id.intID
        context.getters.services.users.update(context.getters.currentUser)

        //Reset filters for new plan
        context.dispatch('resetSelectedFilters')

        //Fetch Subscription events
        context.dispatch('fetchSubscriptionEvents').finally(() => {
          context
            .dispatch('mapDuplicateSubscriptionEvents')
            .finally(() => resolve())
        })
      })
    },
    updateCurrentPlanById: function (context, newPlanId: ID) {
      return new Promise<void>((resolve, reject) => {
        if (!context.getters.allPlans.length) {
          context
            .dispatch('switchAccount', context.getters.routeAccountIntId)
            .then(
              () => {
                context.commit('updateCurrentPlanIntID', newPlanId.intID)
                context.dispatch('refreshCurrentPlan').then((responsePlan) => {
                  if (
                    AppRouter.currentRoute.params.planId &&
                    AppRouter.currentRoute.params.planId !==
                      newPlanId.intID.toString()
                  ) {
                    AppRouter.replace({
                      params: {planId: newPlanId.intID.toString()},
                    })
                  }
                  resolve()
                })
              },
              (error) => {
                reject(
                  'AppStore - updateCurrentPlanById: Could not find Plan for ID.'
                )
              }
            )
        } else {
          const newPlan = context.getters.allPlans.find(
            (plan) => plan.id.intID == newPlanId.intID
          ) as Plan

          if (newPlan) {
            context.commit('updateCurrentPlanIntID', newPlanId.intID)
            context.dispatch('refreshCurrentPlan').then((responsePlan) => {
              if (
                AppRouter.currentRoute.params.planId &&
                AppRouter.currentRoute.params.planId !==
                  newPlan.id.intID.toString()
              ) {
                AppRouter.replace({
                  params: {planId: newPlan.id.intID.toString()},
                })
              }
              resolve()
            })
          } else {
            reject(
              'AppStore - updateCurrentPlanById: Could not find Plan for ID.'
            )
          }
        }
      })
    },
    updateInitiatives: function (state, newInitiatives: Tactic[]) {
      Vue.set(
        state,
        'selectedInitiativesIntIds',
        newInitiatives.map((tactic) => tactic.id.intID)
      )
    },
    resetSelectedFilters: function (context) {
      return new Promise<void>((resolve, reject) => {
        context.commit('updateIsLeadPlanVisible', true)
        if (context.getters.currentPlan.plans.length) {
          context.commit(
            'updateVisibleNestedPlansIntIds',
            context.getters.currentPlan.plans.map((planId) => planId.intID)
          )
        } else if (context.getters.currentPlan.parentId.intID !== 0) {
          context.commit('updateVisibleNestedPlansIntIds', [
            context.getters.currentPlan.id.intID,
          ])
        }
        context.commit('isNestedPlanFilterActive', false)

        context.commit('updateSelectedTacticTypes', context.getters.currentTacticTypes)
        context.commit('isChannelFilterActive', false)
        context.commit('updateSelectedTags', context.getters.currentTags)
        context.commit('isTagFilterActive', false)
        context.commit('updateSelectedSubscriptions', [])
        context.commit('isSubscriptionFilterActive', false)
        context.commit('updateSelectedInitiatives', context.getters.currentInitiatives)
        context.commit('isInitiativeFilterActive', false)
        context.dispatch('setSelectedPlanPropertiesFromLocalStorage')
        resolve()
      })
    },
    refreshCurrentPlan: function (
      context,
      openedChannels: number[] = [],
      planToUse: Plan = {} as Plan
    ) {
      //TODO: This is in place because Plan is the only API that returns whole objects for Channels, Categories, Types, Fields and Tactics. Consider revising this system with API and/or UI changes to be more efficient in the future.
      const planToRefresh: Plan = planToUse.id
        ? planToUse
        : context.getters.currentPlan
      return new Promise((resolve, reject) => {
        context.state.services.plans.get([planToRefresh.id.intID]).then(
          async (returnedPlan) => {
            const userDetails: Plan = returnedPlan as Plan

            if (openedChannels.length) {
              userDetails.channels.forEach((channel) => {
                if (openedChannels.includes(channel.id.intID)) {
                  channel.opened = true
                }
              })
            }

            // Add UserRole objects from Account to Plan object
            (returnedPlan as Plan).userRoles = 
              context.getters.currentAccount.userRoles.filter(
                (accountUserRole) => 
                  (returnedPlan as Plan).userRoleIds.filter(
                    (planUserRoleId) => planUserRoleId.intID === accountUserRole.id.intID
                    ).length ? true : false
              )

            const returnedPlanIndex =
              context.getters.currentAccount.plans.findIndex(
                (plan) => plan.id.intID == (returnedPlan as Plan).id.intID
              )
            context.getters.currentAccount.plans.splice(
              returnedPlanIndex ? returnedPlanIndex : 0,
              1,
              returnedPlan
            )
            context
              .dispatch('updateCurrentPlan', returnedPlan)
              .finally(() => {
                if((returnedPlan as Plan).budgetIds.length){
                  context.getters.services.budgets.get((returnedPlan as Plan).budgetIds.map((id)=>id.intID)).then(
                    (budgets)=>{
                      (returnedPlan as Plan).budgets = budgets
                      resolve(returnedPlan)
                    }, 
                    (error)=>{
                      reject(`AppStore - refreshCurrentPlan: Error fetching budgets for plan. ${error}`)
                    }
                  )
                }else{
                  resolve(returnedPlan)
                }
              })
          },
          (error) => {
            Vue.prototype.$toast.add(
              new AlertMessage(AlertMessageSeverity.error, error)
            )
            reject(error)
          }
        )
      })
    },
    refreshCurrentAccount: function (context) {
      return new Promise((resolve, reject) => {
        context.state.services.accounts
          .get(context.getters.currentAccount.id.intID)
          .then(
            (returnedAccount) => {
              context.commit('updateCurrentAccount', returnedAccount)
              resolve(returnedAccount)
            },
            (error) => {
              Vue.prototype.$toast.add(
                new AlertMessage(AlertMessageSeverity.error, error)
              )
              reject(error)
            }
          )
      })
    },
    refreshCurrentTactic: function (context, updatedTactic?: Tactic) {
      return new Promise((resolve, reject) => {
        if (updatedTactic) {
          const indexOfCurrentTactic =
            context.getters.currentPlan.tactics.findIndex(
              (tactic) => tactic.id.intID == context.getters.currentTacticIntID
            )
          context.getters.currentPlan.tactics.splice(
            indexOfCurrentTactic,
            1,
            updatedTactic
          )
          context.dispatch('populateFrontEndPlanObjects')
          resolve(context.getters.currentPlan.tactics[indexOfCurrentTactic])
        } else {
          context.state.services.tactics
            .get([context.getters.currentTacticIntID])
            .then(
              (returnedTactic) => {
                const indexOfCurrentTactic =
                  context.getters.currentPlan.tactics.findIndex(
                    (tactic) =>
                      tactic.id.intID == context.getters.currentTacticIntID
                  )
                context.getters.currentPlan.tactics.splice(
                  indexOfCurrentTactic,
                  1,
                  returnedTactic
                )
                context.dispatch('populateFrontEndPlanObjects')
                resolve(
                  context.getters.currentPlan.tactics[indexOfCurrentTactic]
                )
              },
              (error) => {
                Vue.prototype.$toast.add(
                  new AlertMessage(AlertMessageSeverity.error, error)
                )
                reject(error)
              }
            )
        }
      })
    },
    addTacticToCurrentPlan: function (context, newTactic: Tactic) {
      return new Promise<void>((resolve, reject) => {
        context.getters.currentPlan.tactics.push(newTactic)
        context.dispatch('populateFrontEndPlanObjects')
        resolve()
      })
    },
    removeTacticFromCurrentPlanById: function (context, tacticId: ID) {
      return new Promise<void>((resolve, reject) => {
        const indexOfTactic = context.getters.currentPlan.tactics.findIndex(
          (tactic) => tactic.id.intID == tacticId.intID
        )
        if (indexOfTactic) {
          context.getters.currentPlan.tactics.splice(indexOfTactic, 1)
          context.dispatch('populateFrontEndPlanObjects')
          resolve()
        } else {
          reject('Could not find tactic for ID.')
        }
      })
    },
    populateFrontEndPlanObjects: function (context) {
      return new Promise<void>((resolve, reject) => {
        //Add Tactic instance references on Plan to TacticType instances
        context.getters.currentPlan.channels.forEach((channel) => {
          const planForThisChannel =
            context.getters.currentPlan.id.intID !== channel.planId.intID
              ? context.getters.allPlans.find(
                  (plan) => plan.id.intID == channel.planId.intID
                )
              : null
          const abbreviatedPlanNameFromChannel = planForThisChannel
            ? planForThisChannel.abbreviatedName
            : ''
          if (channel.isNested && abbreviatedPlanNameFromChannel !== '') {
            channel.abbreviatedPlanName = abbreviatedPlanNameFromChannel
          }
          channel.tacticTypes.forEach((type) => {
            if (channel.isNested && abbreviatedPlanNameFromChannel !== '') {
              type.abbreviatedPlanName = abbreviatedPlanNameFromChannel
            }

            // Populate names on Type instances
            type.channelName = channel.name

            type.tactics = context.getters.currentPlan.tactics.filter(
              (tactic) => {
                if (tactic.tacticTypeId.intID == type.id.intID) {
                  // Populate names on Tactic instances
                  tactic.channelName = channel.name
                  tactic.tacticTypeName = type.name

                  // Populate objects from Plan on Tactic instances
                  tactic.tags = context.getters.currentPlan.tags.filter((tag)=>tactic.tagIds.map((id)=>id.intID).includes(tag.id.intID))
                  tactic.tacticPlatforms = context.getters.currentTacticPlatforms.filter((tacticPlatform)=>tactic.tacticPlatformIds.map((id)=>id.intID).includes(tacticPlatform.id.intID))
                  tactic.documents = context.getters.currentPlan.documents.filter((document)=>tactic.documentIds.map((id)=>id.intID).includes(document.id.intID))

                  if (tactic.isNested) {
                    if (abbreviatedPlanNameFromChannel !== '') {
                      tactic.abbreviatedPlanName =
                        abbreviatedPlanNameFromChannel
                    } else {
                      tactic.abbreviatedPlanName =
                        context.getters.allPlans.find(
                          (plan) => plan.id.intID == tactic.planId.intID
                        )?.abbreviatedName
                    }
                  }

                  return tactic
                }
              }
            )
          })
        })
        // Add abbreviated plan names to tags
        context.getters.currentPlan.tags.forEach((tag) => {
          const planForThisTag =
            context.getters.currentPlan.id.intID !== tag.planId.intID
              ? context.getters.allPlans.find(
                  (plan) => plan.id.intID == tag.planId.intID
                )
              : null
          const abbreviatedPlanNameFromTag = planForThisTag
            ? planForThisTag.abbreviatedName
            : ''
          if (tag.isNested && abbreviatedPlanNameFromTag !== '') {
            tag.abbreviatedPlanName = abbreviatedPlanNameFromTag
          }
        })
        // Add dashboards to categories
        context.getters.currentPlan.planDashboardCategories.forEach((category) => {
          category.dashboards = context.getters.currentPlan.dashboards
            .filter((dashboard) => dashboard.category.id.intID === category.id.intID)
            .map((dashboard) => {
              dashboard.plan = context.getters.currentPlan.id.clone()
              return dashboard
            })
        })
        resolve()
      })
    },
    updateMostRecentCoreView: function (context, viewName: UserMostRecentView) {
      // Removed references to this action to prevent excessive API calls - JV 03.17.23
      return new Promise<void>((resolve, reject) => {
        context.getters.currentUser.mostRecentView = viewName
        context.getters.services.users.update(context.getters.currentUser)
        resolve()
      })
    },
    fetchSubscriptionEvents: function (context) {
      let processedSubscriptions = 0
      return new Promise<void>((resolve, reject) => {
        if (!context.getters.currentSubscriptions.length) {
          resolve()
        } else {
          context.getters.currentSubscriptions.forEach(
            async (subscription, subscriptionIndex) => {
              if (
                !subscription.events.length ||
                Date.now() - subscription.lastUpdate.getTime() > 86400000
              ) {
                await context.state.services.accounts
                  .getSubscriptionEvents(subscription)
                  .then(
                    (response) => {
                      subscription.lastUpdate = new Date()
                      subscription.events = response as Tactic[]

                      subscription.events.forEach(
                        (subscriptionEvent, eventIndex) => {
                          const thisIntId = Number(
                            subscriptionIndex.toString() + eventIndex.toString()
                          )
                          subscriptionEvent.id = new ID(
                            thisIntId,
                            '/' + subscription.name + '/' + thisIntId.toString()
                          )
                          subscriptionEvent.channelName = subscription.name
                          subscriptionEvent.isSubscriptionEvent = true

                          // Account for timezone offset
                          subscriptionEvent.startDate = new Date(subscriptionEvent.startDate.getTime() + (subscriptionEvent.startDate.getTimezoneOffset() * 60000));
                          subscriptionEvent.endDate = new Date(subscriptionEvent.endDate.getTime() + (subscriptionEvent.endDate.getTimezoneOffset() * 60000));

                          // Should event be allDay?
                          if (
                            subscriptionEvent.startDate &&
                            subscriptionEvent.endDate &&
                            subscriptionEvent.endDate -
                              subscriptionEvent.startDate >=
                              86400000 &&
                            subscriptionEvent.startDate.getUTCHours() == 0 &&
                            subscriptionEvent.endDate.getUTCHours() == 0
                          ) {
                            subscriptionEvent.isAllDay = true
                            subscriptionEvent.startDate.setDate(
                              subscriptionEvent.startDate.getDate() + 1
                            )
                          }
                          // Set DTSTART for subscription events that don't have one
                          if (subscriptionEvent.rrule && subscriptionEvent.rrule?.indexOf('DTSTART=') < 0) {
                            const year = subscriptionEvent.startDate.getFullYear()
                            const month =
                              subscriptionEvent.startDate.getMonth() > 8
                                ? subscriptionEvent.startDate.getMonth() + 1
                                : '0' + (subscriptionEvent.startDate.getMonth() + 1)
                            const date =
                              subscriptionEvent.startDate.getDate() > 9
                                ? subscriptionEvent.startDate.getDate()
                                : '0' + subscriptionEvent.startDate.getDate()
                            const hours =
                              subscriptionEvent.startDate.getHours() > 9
                                ? subscriptionEvent.startDate.getHours()
                                : '0' + subscriptionEvent.startDate.getHours()
                            const minutes =
                              subscriptionEvent.startDate.getMinutes() > 9
                                ? subscriptionEvent.startDate.getMinutes()
                                : '0' + subscriptionEvent.startDate.getMinutes()
                            const seconds =
                              subscriptionEvent.startDate.getSeconds() > 9
                                ? subscriptionEvent.startDate.getSeconds()
                                : '0' + subscriptionEvent.startDate.getSeconds()
                            const dtStart = subscriptionEvent.startDate
                              ? `DTSTART=${year}${month}${date}T${hours}${minutes}${seconds};`
                              : ''
                              subscriptionEvent.rrule = `${dtStart}${subscriptionEvent.rrule}`
                          }
                        }
                      )

                      processedSubscriptions += 1
                      if (
                        processedSubscriptions ==
                        context.getters.currentSubscriptions.length
                      ) {
                        resolve()
                      }
                    },
                    (error) => {
                      Vue.prototype.$toast.add(
                        new AlertMessage(
                          AlertMessageSeverity.error,
                          'Error Fetching Subscription Events',
                          error
                        )
                      )
                      processedSubscriptions += 1
                      if (
                        processedSubscriptions ==
                        context.getters.currentSubscriptions.length
                      ) {
                        reject()
                      }
                    }
                  )
              } else {
                processedSubscriptions += 1
                if (
                  processedSubscriptions ==
                  context.getters.currentSubscriptions.length
                ) {
                  resolve()
                }
              }
            }
          )
        }
      })
    },
    mapDuplicateSubscriptionEvents: function (context) {
      return new Promise<void>((resolve, reject) => {
        if (!context.getters.currentSubscriptions.length) {
          resolve()
        } else {
          context.getters.currentSubscriptions.forEach(
            (subscription, subscriptionIndex) => {
              subscription.duplicateEventsMap = []
              subscription.events.forEach((event) => {
                context.getters.currentSubscriptions.forEach(
                  (testSubscription) => {
                    if (testSubscription.id.intID == subscription.id.intID) {
                      return
                    }
                    const duplicateEvents = testSubscription.events.filter(
                      (testEvent) => {
                        if (
                          event.title == testEvent.title &&
                          ((event.isAllDay && testEvent.isAllDay) ||
                            (event.startDate == testEvent.startDate &&
                              event.endDate == testEvent.endDate))
                        ) {
                          return true
                        }
                        return false
                      }
                    )
                    if (duplicateEvents.length) {
                      const formattedDuplicateEvents = duplicateEvents.map(
                        (duplicateEvent) => {
                          return new DuplicateSubscriptionEventMapping(
                            subscription.id.intID,
                            event.id.intID,
                            testSubscription.id.intID,
                            duplicateEvent.id.intID
                          )
                        }
                      )
                      subscription.duplicateEventsMap =
                        subscription.duplicateEventsMap.concat(
                          formattedDuplicateEvents
                        )
                    }
                  }
                )
              })

              if (
                subscriptionIndex + 1 ==
                context.getters.currentSubscriptions.length
              ) {
                resolve()
              }
            }
          )
        }
      })
    },

    documentToCategoriesForCurrentPlan: function (context) {
      const plan = context.getters.currentPlan
      const documents: PlanDocument[] = context.getters.currentPlan.documents
      const planDocumentCategories = context.getters.currentPlan.planDocumentCategories

      if (
        planDocumentCategories[planDocumentCategories.length - 1]?.id?.intID !==
        0
      ) {
        planDocumentCategories.push(new PlanDocumentCategory('Uncategorized'))
      }

      plan.planDocumentCategories = planDocumentCategories.map((category) => {
        const isUncategorized = category.name === 'Uncategorized'

        category.planDocuments = documents
          .map((doc) => {
            const categoryIndex = doc.categories.find(
              (c) => c.id?.intID === category.id?.intID
            )

            if (categoryIndex) {
              return doc
            } else if (isUncategorized && categoryIndex === -1) {
              return doc
            } else {
              return new PlanDocument()
            }
          })
          .filter((doc) => doc.id.intID !== 0)

        return category
      }) as PlanDocumentCategory[]

      context.dispatch('refreshCurrentPlan', plan)
    },

    //-----------------------------------------------//
    //Manage filters and localstorage
    //-----------------------------------------------//
    readQueryParams: function (context, queryParams) {
      return new Promise<void>((resolve, reject) => {
        //Test query string: ?ds-47=1-1-2021&de-47=12-31-2021&ch-47=12,13&in-47=21&tg-47=33,32&sb-47=44
        Object.keys(queryParams).forEach((param) => {
          if (queryParams[param]) {
            window.localStorage.setItem(param, queryParams[param])
          } else {
            window.localStorage.removeItem(param)
          }
        })
        resolve()
      })
    },
    setSelectedPlanPropertiesFromLocalStorage: function (context) {
      return new Promise<void>((resolve, reject) => {
        const filters = {
          lpl: window.localStorage.getItem(
            'lpl-' + context.getters.currentPlanIntID.toString()
          ),
          npl: window.localStorage.getItem(
            'npl-' + context.getters.currentPlanIntID.toString()
          ),
          tp: window.localStorage.getItem(
            'tp-' + context.getters.currentPlanIntID.toString()
          ),
          in: window.localStorage.getItem(
            'in-' + context.getters.currentPlanIntID.toString()
          ),
          tg: window.localStorage.getItem(
            'tg-' + context.getters.currentPlanIntID.toString()
          ),
          sb: window.localStorage.getItem(
            'sb-' + context.getters.currentPlanIntID.toString()
          ),
        }

        //Lead Plan
        context.commit('updateIsLeadPlanVisible', filters.lpl === 'false' ? false : true)

        //Nested Plans
        if (filters.npl !== null) {
          const nestedPlanFilterIds = filters.npl.split(',')
          if (nestedPlanFilterIds.length > 0 && nestedPlanFilterIds.length < context.getters.currentPlan.plans.length) {
            context.commit('updateVisibleNestedPlansIntIds', nestedPlanFilterIds.filter((idString) => idString !== '').map((idString => parseInt(idString))))
            context.commit('isNestedPlanFilterActive', true)
          }else{
            const planIds = context.getters.currentPlan.plans.length ? context.getters.currentPlan.plans.map((planId) => planId.intID) : [context.getters.currentPlan.id.intID]
            context.commit('updateVisibleNestedPlansIntIds', planIds)
            context.commit('isNestedPlanFilterActive', false)
          }
        }else{
          const planIds = context.getters.currentPlan.plans.length ? context.getters.currentPlan.plans.map((planId) => planId.intID) : [context.getters.currentPlan.id.intID]
          context.commit('updateVisibleNestedPlansIntIds', planIds)
          context.commit('isNestedPlanFilterActive', false)
        }

        // Tactic Types
        if (filters.tp !== null) {
          if (filters.tp.length > 0) {
            const filterIds = filters.tp.split(',')
            context.commit(
              'updateSelectedTacticTypes',
              context.getters.currentTacticTypes.filter(
                (type) => filterIds.indexOf(type.id.intID.toString()) > -1
              )
            )
            context.commit('isChannelFilterActive', true)
          } else {
            context.commit(
              'updateSelectedTacticTypes',
              context.getters.currentTacticTypes
            )
            context.commit('isChannelFilterActive', false)
          }
        } else {
          context.commit(
            'updateSelectedTacticTypes',
            context.getters.currentTacticTypes
          )
          context.commit('isChannelFilterActive', false)
        }
        //Initiatives
        if (filters.in !== null) {
          const filterIds = filters.in.split(',')
          if (filters.in.length > 0) {
            context.commit(
              'updateSelectedInitiatives',
              context.getters.currentInitiatives.filter(
                (initiative) =>
                  filterIds.indexOf(initiative.id.intID.toString()) > -1
              )
            )
            context.commit('isInitiativeFilterActive', true)
          } else {
            context.commit('updateSelectedInitiatives', [])
            context.commit('isInitiativeFilterActive', false)
          }
        } else {
          context.commit(
            'updateSelectedInitiatives',
            context.getters.currentInitiatives
          )
          context.commit('isInitiativeFilterActive', false)
        }
        //Tags
        if (filters.tg !== null) {
          if (filters.tg.length > 0) {
            const filterIds = filters.tg.split(',')
            context.commit(
              'updateSelectedTags',
              context.getters.currentTags.filter(
                (tag) => filterIds.indexOf(tag.id.intID.toString()) > -1
              )
            )
            context.commit('isTagFilterActive', true)
          } else {
            context.commit('updateSelectedTags', [])
            context.commit('isTagFilterActive', false)
          }
        } else {
          context.commit('updateSelectedTags', context.getters.currentTags)
          context.commit('isTagFilterActive', false)
        }
        //Subscriptions
        if (filters.sb !== null) {
          if (filters.sb.length > 0) {
            const filterIds = filters.sb.split(',')
            context.commit(
              'updateSelectedSubscriptions',
              context.getters.currentSubscriptions.filter(
                (subscription) =>
                  filterIds.indexOf(subscription.id.intID.toString()) > -1
              )
            )
            context.commit('isSubscriptionFilterActive', true)
          } else {
            context.commit('updateSelectedSubscriptions', [])
            context.commit('isSubscriptionFilterActive', false)
          }
        } else {
          context.commit('updateSelectedSubscriptions', [])
          context.commit('isSubscriptionFilterActive', false)
        }

        // Update filters badge count
        context.dispatch('updateActiveFiltersCount')

        resolve()
      })
    },
    updateActiveFiltersCount: function(context){
      let newActiveFiltersCount = 0
      // Tactic Types
      if(context.getters.isChannelFilterActive){
        newActiveFiltersCount += context.getters.selectedTacticTypes.length
      }
      // Initiatives
      if(context.getters.isInitiativeFilterActive){
        newActiveFiltersCount += context.getters.selectedInitiatives.length
      }
      // Tags
      if(context.getters.isTagFilterActive){
        newActiveFiltersCount += context.getters.selectedTags.length
      }
      // Subscriptions
      if(context.getters.isSubscriptionFilterActive){
        newActiveFiltersCount += context.getters.selectedSubscriptions.length
      }
      // Nested Plans
      if(context.getters.currentPlan.plans.length > 0 && context.getters.visibleNestedPlansIntIds.length < context.getters.currentPlan.plans.length){
        newActiveFiltersCount += context.getters.currentPlan.plans.length - context.getters.visibleNestedPlansIntIds.length
      }
      // Lead Plan
      if(context.getters.currentPlan.plans.length === 0 && !context.getters.isLeadPlanVisible){
        newActiveFiltersCount += 1
      }
      context.dispatch('setActiveFiltersCount', newActiveFiltersCount)
    },
    updateCurrentDateRange: function (context, newDateRange: Date[]) {
      // Default to current month
      if(!newDateRange || !newDateRange.length){
        newDateRange = [new Date(), new Date()]
        newDateRange[0].setDate(1)
        newDateRange[1].setMonth(newDateRange[1].getMonth() + 1)
        newDateRange[1].setDate(0)
      }

      // Set to beginning and end of days in current month
      newDateRange[0].setHours(0,0,0,0)
      newDateRange[1].setHours(23,59,59,999)

      // Update date range properties
      Vue.set(context.state, 'currentDateRange', [...newDateRange])
      if(!context.state.recentDateRangesDictionary[context.getters.currentPlanIntID]){
        context.state.recentDateRangesDictionary[context.getters.currentPlanIntID] = {}
      }
      context.state.recentDateRangesDictionary[context.getters.currentPlanIntID]['main'] = [...newDateRange]
    },
    updateMonthViewDateRange: function (context, newRange: Date[]) {
      Vue.set(context.state, 'monthViewDateRange', newRange)
      if(!context.state.recentDateRangesDictionary[context.getters.currentPlanIntID]){
        context.state.recentDateRangesDictionary[context.getters.currentPlanIntID] = {}
      }
      context.state.recentDateRangesDictionary[context.getters.currentPlanIntID]['monthView'] = [...newRange]
    },
    updateDayViewDateRange: function (context, newRange: Date[]) {
      Vue.set(context.state, 'dayViewDateRange', newRange)
      if(!context.state.recentDateRangesDictionary[context.getters.currentPlanIntID]){
        context.state.recentDateRangesDictionary[context.getters.currentPlanIntID] = {}
      }
      context.state.recentDateRangesDictionary[context.getters.currentPlanIntID]['dayView'] = [...newRange]
    },
    updateRangeViewsDateRange: function (context, newRange: Date[]) {
      Vue.set(context.state, 'rangeViewsDateRange', newRange)
      if(!context.state.recentDateRangesDictionary[context.getters.currentPlanIntID]){
        context.state.recentDateRangesDictionary[context.getters.currentPlanIntID] = {}
      }
      context.state.recentDateRangesDictionary[context.getters.currentPlanIntID]['rangeViews'] = [...newRange]
    }, 
    updateInitiativesDateRange: function (context, newRange: Date[]) {
      Vue.set(context.state, 'initiativesDateRange', newRange)
      if(!context.state.recentDateRangesDictionary[context.getters.currentPlanIntID]){
        context.state.recentDateRangesDictionary[context.getters.currentPlanIntID] = {}
      }
      context.state.recentDateRangesDictionary[context.getters.currentPlanIntID]['initiatives'] = [...newRange]
    }, 
    setAllDateRanges: function (context, newRange: Date[] = []){
      if(newRange.length){
        context.dispatch('updateCurrentDateRange', newRange)
      }else{
        context.dispatch('updateCurrentDateRange')
      }
      context.dispatch('updateRangeViewsDateRange', context.getters.currentDateRange)
      context.dispatch('updateMonthViewDateRange', context.getters.currentDateRange)
      context.dispatch('updateDayViewDateRange', context.getters.currentDateRange)

      // Set initiatives range to 12 months from current date
      const initiativesRangeStart = new Date()
      const initiativesRangeEnd = new Date()
      initiativesRangeEnd.setMonth(initiativesRangeStart.getMonth() + 12)
      context.dispatch('updateInitiativesDateRange', [initiativesRangeStart, initiativesRangeEnd])
    },
    restoreAllDateRangesForCurrentPlan: function (context){
      context.dispatch('updateCurrentDateRange', context.state.recentDateRangesDictionary[context.getters.currentPlanIntID]['main'])
      context.dispatch('updateRangeViewsDateRange', context.state.recentDateRangesDictionary[context.getters.currentPlanIntID]['rangeViews'])
      context.dispatch('updateMonthViewDateRange', context.state.recentDateRangesDictionary[context.getters.currentPlanIntID]['monthView'])
      context.dispatch('updateDayViewDateRange', context.state.recentDateRangesDictionary[context.getters.currentPlanIntID]['dayView'])
      context.dispatch('updateInitiativesDateRange', context.state.recentDateRangesDictionary[context.getters.currentPlanIntID]['initiatives'])
    },
    updateFeedbackModal: function (context, isOpen: boolean) {
      context.commit('updateIsFeedbackModalOpen', isOpen)
    },
    addRecurringEventClones: function (context, subscription) {
      subscription.events.forEach((tactic) => {
        if (!tactic.rrule) {
          return
        }
        const diff = differenceInMinutes(tactic.endDate, tactic.startDate)
        const rules = rrulestr(tactic.rrule)

        // Adjust start and end dates to cover events that start or end in the precending or subsequent month from the current date range
        const testStartDate = new Date(context.state.currentDateRange[0])
        testStartDate.setMonth(testStartDate.getMonth() - 1)
        testStartDate.setDate(0)
        testStartDate.setHours(-1)
        const testEndDate = new Date(context.state.currentDateRange[1])
        testEndDate.setMonth(testEndDate.getMonth() + 1)
        testEndDate.setDate(1)
        
        const recurrenceDates = rules
          .between(
            testStartDate,
            testEndDate
          )
          .filter((recurrenceStartDate) => {
            // Filter out original tactic and any clones prior to it
            return recurrenceStartDate > tactic.startDate
          })

        if (recurrenceDates.length) {
          let newRecurrences = recurrenceDates.map((recurrenceDate, i) => {
            // Account for timezone offset
            recurrenceDate = new Date(recurrenceDate.getTime() + (recurrenceDate.getTimezoneOffset() * 60000));
            
            return {
              ...tactic,
              id: new ID(Number(tactic.id.intID.toString() + '0000' + i.toString())),
              startDate: recurrenceDate,
              endDate: addMinutes(recurrenceDate, diff),
              isRecurringEventClone: true,
            }
          })

          // need to filter out here because on refresh it tries to add them again
          newRecurrences = newRecurrences.filter((newRecurrence) => {
            const match = subscription.events.find((t) => {
              return (
                isSameDay(newRecurrence.startDate, t.startDate) &&
                tactic.id.apiID === t.id.apiID
              )
            })
            return !match
          })

          subscription.events = subscription.events.concat(newRecurrences)
        }
      })
    },
    setActiveFiltersCount(context, count) {
      Vue.set(
        context.state,
        'activeFiltersCount',
        count == '0' ? '' : count.toString()
      )
    },
    getViewRoles(context) {
      return new Promise((resolve, reject) => {
        context.state.services.plans
          .getViewedRoles()
          .then((response) => {
            resolve(true)
          })
          .catch((error) => reject(error))
      })
    },
    updateViewRoles(context, payload) {
      return new Promise((resolve, reject) => {
        context.state.services.plans
          .updateVisitedPlan(payload)
          .then((response) => {
            resolve(response)
          })
          .catch((error) => reject(error))
      })
    },

    getPlanFromID: (context, payload) => {
      return new Promise((resolve, reject) => {
        context.state.services.plans
          .get(payload)
          .then((response) => {
            resolve(response)
          })
          .catch((error) => reject(error))
      })
    },

    //-----------------------------------------------//
    //API communication pass-throughs
    //-----------------------------------------------//

    //Account Service
    // createAccount(context, newAccount: Account) {
    //   return context.state.services.accounts.create(newAccount).then(
    //     (response) => {
    //       return response
    //     },
    //     (error) => {
    //       Vue.prototype.$toast.add(
    //         new AlertMessage(AlertMessageSeverity.error, error)
    //       )
    //     }
    //   )
    // },

    // getAccount(context, ids: number[]) {
    //   return context.state.services.accounts.get(ids).then(
    //     (response) => {
    //       return response
    //     },
    //     (error) => {
    //       Vue.prototype.$toast.add(
    //         new AlertMessage(AlertMessageSeverity.error, error)
    //       )
    //     }
    //   )
    // },

    // updateAccount(context, updatedAccount: Account) {
    //   return context.state.services.accounts.update(updatedAccount).then(
    //     (response) => {
    //       return response
    //     },
    //     (error) => {
    //       Vue.prototype.$toast.add(
    //         new AlertMessage(AlertMessageSeverity.error, error)
    //       )
    //     }
    //   )
    // },

    // deleteAccount(context, ids: number[]) {
    //   return context.state.services.accounts.delete(ids).then(
    //     (response) => {
    //       return response
    //     },
    //     (error) => {
    //       Vue.prototype.$toast.add(
    //         new AlertMessage(AlertMessageSeverity.error, error)
    //       )
    //     }
    //   )
    // },

    // getAccountRoles(context) {
    //   return context.state.services.accounts.getRoles().then(
    //     (response) => {
    //       return response
    //     },
    //     (error) => {
    //       Vue.prototype.$toast.add(
    //         new AlertMessage(AlertMessageSeverity.error, error)
    //       )
    //     }
    //   )
    // },

    // getAccountSubscriptions(context) {
    //   return context.state.services.accounts.getSubscriptions().then(
    //     (response) => {
    //       return response
    //     },
    //     (error) => {
    //       Vue.prototype.$toast.add(
    //         new AlertMessage(AlertMessageSeverity.error, error)
    //       )
    //     }
    //   )
    // },

    //Channel Service
    // createChannel(context, newChannel: Channel) {
    //   return context.state.services.channels.create(newChannel).then(
    //     (response) => {
    //       return response
    //     },
    //     (error) => {
    //       Vue.prototype.$toast.add(
    //         new AlertMessage(AlertMessageSeverity.error, error)
    //       )
    //     }
    //   )
    // },

    // getChannel(context, ids: number[]) {
    //   return context.state.services.channels.get(ids).then(
    //     (response) => {
    //       return response
    //     },
    //     (error) => {
    //       Vue.prototype.$toast.add(
    //         new AlertMessage(AlertMessageSeverity.error, error)
    //       )
    //     }
    //   )
    // },

    // updateChannel(context, updatedChannel: Channel) {
    //   return context.state.services.channels.update(updatedChannel).then(
    //     (response) => {
    //       return response
    //     },
    //     (error) => {
    //       Vue.prototype.$toast.add(
    //         new AlertMessage(AlertMessageSeverity.error, error)
    //       )
    //     }
    //   )
    // },

    // deleteChannel(context, ids: number[]) {
    //   return context.state.services.channels.delete(ids).then(
    //     (response) => {
    //       return response
    //     },
    //     (error) => {
    //       Vue.prototype.$toast.add(
    //         new AlertMessage(AlertMessageSeverity.error, error)
    //       )
    //     }
    //   )
    // },

    // createTacticCategory(context, newTacticCategory: TacticCategory) {
    //   return context.state.services.channels
    //     .createTacticCategory(newTacticCategory)
    //     .then(
    //       (response) => {
    //         return response
    //       },
    //       (error) => {
    //         Vue.prototype.$toast.add(
    //           new AlertMessage(AlertMessageSeverity.error, error)
    //         )
    //       }
    //     )
    // },

    // CommentService
    // createComment(context, newComment: Comment) {
    //   return context.state.services.comments.create(newComment).then(
    //     (response) => {
    //       return response
    //     },
    //     (error) => {
    //       Vue.prototype.$toast.add(
    //         new AlertMessage(AlertMessageSeverity.error, error)
    //       )
    //     }
    //   )
    // },

    // getComment(context, ids: number[]) {
    //   return context.state.services.comments.get(ids).then(
    //     (response) => {
    //       return response
    //     },
    //     (error) => {
    //       Vue.prototype.$toast.add(
    //         new AlertMessage(AlertMessageSeverity.error, error)
    //       )
    //     }
    //   )
    // },

    // updateComment(context, updatedComment: Comment) {
    //   return context.state.services.comments.update(updatedComment).then(
    //     (response) => {
    //       return response
    //     },
    //     (error) => {
    //       Vue.prototype.$toast.add(
    //         new AlertMessage(AlertMessageSeverity.error, error)
    //       )
    //     }
    //   )
    // },

    // deleteComment(context, ids: number[]) {
    //   return context.state.services.comments.delete(ids).then(
    //     (response) => {
    //       return response
    //     },
    //     (error) => {
    //       Vue.prototype.$toast.add(
    //         new AlertMessage(AlertMessageSeverity.error, error)
    //       )
    //     }
    //   )
    // },

    //Tactics Service
    // createTactic(context, newTactic: Tactic) {
    //   return context.state.services.tactics.create(newTactic).then(
    //     (response) => {
    //       return response
    //     },
    //     (error) => {
    //       Vue.prototype.$toast.add(
    //         new AlertMessage(AlertMessageSeverity.error, error)
    //       )
    //     }
    //   )
    // },

    // getTactic(context, ids: number[]) {
    //   return context.state.services.tactics.get(ids).then(
    //     (response) => {
    //       return response
    //     },
    //     (error) => {
    //       Vue.prototype.$toast.add(
    //         new AlertMessage(AlertMessageSeverity.error, error)
    //       )
    //     }
    //   )
    // },

    // updateTactic(context, updatedTactic: Tactic) {
    //   return context.state.services.tactics.update(updatedTactic).then(
    //     (response) => {
    //       return response
    //     },
    //     (error) => {
    //       Vue.prototype.$toast.add(
    //         new AlertMessage(AlertMessageSeverity.error, error)
    //       )
    //     }
    //   )
    // },

    // deleteTactic(context, ids: number[]) {
    //   return context.state.services.tactics.delete(ids).then(
    //     (response) => {
    //       return response
    //     },
    //     (error) => {
    //       Vue.prototype.$toast.add(
    //         new AlertMessage(AlertMessageSeverity.error, error)
    //       )
    //     }
    //   )
    // },

    // importTactic(context, fileBinary: string) {
    //   return context.state.services.tactics.import(fileBinary).then(
    //     (response) => {
    //       return response
    //     },
    //     (error) => {
    //       Vue.prototype.$toast.add(
    //         new AlertMessage(AlertMessageSeverity.error, error)
    //       )
    //     }
    //   )
    // },

    // searchTactic(context, params) {
    //   return context.state.services.tactics
    //     .search(
    //       params.planId,
    //       params.keyword,
    //       params.startDate,
    //       params.endDate,
    //       params.userId,
    //       params.channelId,
    //       params.typeId
    //     )
    //     .then(
    //       (response) => {
    //         return response
    //       },
    //       (error) => {
    //         Vue.prototype.$toast.add(
    //           new AlertMessage(AlertMessageSeverity.error, error)
    //         )
    //       }
    //     )
    // },

    //User service
    // createUser(context, newUser: User) {
    //   return context.state.services.users.create(newUser).then(
    //     (response) => {
    //       return response
    //     },
    //     (error) => {
    //       Vue.prototype.$toast.add(
    //         new AlertMessage(AlertMessageSeverity.error, error)
    //       )
    //     }
    //   )
    // },

    // getUser(context, ids: number[]) {
    //   return context.state.services.users.get(ids).then(
    //     (response) => {
    //       return response
    //     },
    //     (error) => {
    //       Vue.prototype.$toast.add(
    //         new AlertMessage(AlertMessageSeverity.error, error)
    //       )
    //     }
    //   )
    // },

    // updateUser(context, updatedUser: User) {
    //   return context.state.services.users.update(updatedUser).then(
    //     (response) => {
    //       return response
    //     },
    //     (error) => {
    //       Vue.prototype.$toast.add(
    //         new AlertMessage(AlertMessageSeverity.error, error)
    //       )
    //     }
    //   )
    // },

    // deleteUser(context, userToDelete: User) {
    //   return context.state.services.users.delete(userToDelete).then(
    //     (response) => {
    //       return response
    //     },
    //     (error) => {
    //       Vue.prototype.$toast.add(
    //         new AlertMessage(AlertMessageSeverity.error, error)
    //       )
    //     }
    //   )
    // },

    // inviteUser(context, newUserInvite: UserInvite) {
    //   return context.state.services.users.invite(newUserInvite).then(
    //     (response) => {
    //       return response
    //     },
    //     (error) => {
    //       Vue.prototype.$toast.add(
    //         new AlertMessage(AlertMessageSeverity.error, error)
    //       )
    //     }
    //   )
    // },

    // deleteUserInvite(context, ids: number[]) {
    //   return context.state.services.users.deleteUserInvite(ids).then(
    //     (response) => {
    //       return response
    //     },
    //     (error) => {
    //       Vue.prototype.$toast.add(
    //         new AlertMessage(AlertMessageSeverity.error, error)
    //       )
    //     }
    //   )
    // },

    // forgotPassword(context, emailAddress: string) {
    //   return context.state.services.users.forgotPassword(emailAddress).then(
    //     (response) => {
    //       return response
    //     },
    //     (error) => {
    //       Vue.prototype.$toast.add(
    //         new AlertMessage(AlertMessageSeverity.error, error)
    //       )
    //     }
    //   )
    // },

    // mediaAsset(context, file) {
    //   return context.state.services.users.mediaAsset(file).then(
    //     (response) => {
    //       return response
    //     },
    //     (error) => {
    //       Vue.prototype.$toast.add(
    //         new AlertMessage(AlertMessageSeverity.error, error)
    //       )
    //     }
    //   )
    // },

    //TagService
    // createTag(context, newTactic: Tag) {
    //   return context.state.services.tags.create(newTactic).then(
    //     (response) => {
    //       return response
    //     },
    //     (error) => {
    //       Vue.prototype.$toast.add(
    //         new AlertMessage(AlertMessageSeverity.error, error)
    //       )
    //     }
    //   )
    // },

    // getTag(context, ids: number[]) {
    //   return context.state.services.tags.get(ids).then(
    //     (response) => {
    //       return response
    //     },
    //     (error) => {
    //       Vue.prototype.$toast.add(
    //         new AlertMessage(AlertMessageSeverity.error, error)
    //       )
    //     }
    //   )
    // },

    // updateTag(context, updatedTactic: Tag) {
    //   return context.state.services.tags.update(updatedTactic).then(
    //     (response) => {
    //       return response
    //     },
    //     (error) => {
    //       Vue.prototype.$toast.add(
    //         new AlertMessage(AlertMessageSeverity.error, error)
    //       )
    //     }
    //   )
    // },

    // deleteTag(context, ids: number[]) {
    //   return context.state.services.tags.delete(ids).then(
    //     (response) => {
    //       return response
    //     },
    //     (error) => {
    //       Vue.prototype.$toast.add(
    //         new AlertMessage(AlertMessageSeverity.error, error)
    //       )
    //     }
    //   )
    // },
  },
  modules: {},
})
